import { createAsyncThunk } from '@reduxjs/toolkit';
// import { Howler } from 'howler';
import { Auth } from 'aws-amplify';
import { StoreKey } from '../store-keys';
import { RootState } from '../store';
import {
  changeHomeView,
  openMenu,
  readyForUpdate,
  resetAppState,
  setBackground,
  weatherOutdated,
} from '../reducers/appSlice';
import { updateIsPlaying } from '../reducers/playerSlice';
import { userLoginCheck } from '../reducers/authSlice';
import { apiClient } from '../../api/api';
import hideModalAction from './hideModalAction';
import { HomeViewType } from '../types';
import {
  getStoreData,
  sendAnalyticsEvent,
  setStoreData,
} from '../../platform-utils/utils';
import { AnalyticsEvent } from '../service/analytics-events';
import { useNavigate } from 'react-router-dom';

export default createAsyncThunk<unknown, void, { state: RootState }>(
  'app/logout',
  async (_, { dispatch }) => {
    dispatch(openMenu(false));
    dispatch(updateIsPlaying(false));
    // Howler.stop();
    // Howler.unload();
    dispatch(resetAppState());

    const appData = await getStoreData(StoreKey.AppData);
    if (appData) {
      // Update token
      appData.userTokenData = null;
      // Update store
      setStoreData(StoreKey.AppData, appData);
      setStoreData(StoreKey.ShowRefreshModal, false);
    }
    sendAnalyticsEvent(AnalyticsEvent.Logout);
    delete apiClient.defaults.headers.common.Authorization;

    try {
      await Auth.signOut();
      // Update login
    } catch (error) {
      console.log('error signing out: ', error);
    }

    dispatch(weatherOutdated(true));
    dispatch(changeHomeView(HomeViewType.Home));
    dispatch(hideModalAction('all'));
    dispatch(userLoginCheck(appData));
    dispatch(readyForUpdate());
    dispatch(setBackground(null));

    if (window && window.electron) {
      window.electron.player.toggle(false);
      window.electron.auth.logout();
    }
    setStoreData(StoreKey.UserGlobe, null);

  }
);
