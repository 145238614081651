import { FC, memo, MouseEvent, ReactNode } from 'react';
import { useWhyDidYouUpdate } from '../../../hooks/useWhyDidYouUpdate';
import './button.scss';

interface ButtonProps {
  onClick: () => void;
  variant:
  'light'
  | 'green'
  | 'dark'
  | 'link'
  | 'white'
  | 'danger'
  | 'green-lined';
  rounded?: boolean;
  isActive?: boolean;
  className?: string;
  style?: CSSStyleSheet;
  children?: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    onClick,
    disabled = false,
    variant = 'light',
    isActive = false,
    rounded = false,
    className,
    type = 'button',
    style = {},
  } = props;
  useWhyDidYouUpdate(`Button-${className}`, props);

  const onMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    return false;
  };

  const onClickAction = () => {
    if (!disabled && !isActive && onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`button-wrapper ${className ?? ''} ${rounded ? 'round1' : ''
        } ${isActive ? 'is-active' : ''}`}
    >
      <div className="loading-border">
        <button
          type={type}
          className={`button ${variant} ${disabled ? "disabled" : ""} `}
          style={style}
          disabled={disabled}
          onClick={onClick ? onClickAction : undefined}
          onMouseDown={onMouseDown}
        >
          {children}
        </button>
        {variant !== 'link' && <div className="loading-animation" />}
      </div>
    </div>
  );
};

export default memo(Button);
