import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { updateUser as updateUserApi } from '../../api/api';
import {
  updateUserData,
  updateUserGeneral,
  updateUserPreferences,
} from '../reducers/userSlice';
import notifyTrayAction from './notifyTrayAction';
import { sendAnalyticsEvent } from '../../platform-utils/utils';

let userUpdateDebounceTimer: NodeJS.Timeout;
export default createAsyncThunk<any, any, { state: RootState }>(
  'user/update',
  async (data, { dispatch, getState }) => {
    const { user } = getState();

    if (userUpdateDebounceTimer) {
      clearTimeout(userUpdateDebounceTimer);
    }

    const userUpdate = {
      first_name: user.general.first_name,
      last_name: user.general.last_name,
      profile_picture: user.general.profile_picture ?? '',
      weather_sync: user.preferences.weather_sync,
      receive_emails: user.preferences.receive_emails,
      receive_push: user.preferences.receive_push,
      play_on_startup: user.preferences.play_on_startup,
      load_on_startup: user.preferences.load_on_startup,
      last_main_vol: user.preferences.last_main_vol,
      last_env_vol: user.preferences.last_env_vol,
      last_weather_vol: user.preferences.last_weather_vol,
      temp_unit: user.preferences.temp_unit,
    };

    const userUp = { ...user };
    const changes = [];
    Object.keys(data).forEach((key) => {
      if (Object.keys(user.preferences).includes(key)) {
        userUp.preferences = { ...userUp.preferences, [key]: data[key] };
        changes.push([key, data[key]]);
      }
      if (Object.keys(user.general).includes(key)) {
        userUp.general = { ...userUp.general, [key]: data[key] };
        changes.push([key, data[key]]);
      }
    });

    dispatch(updateUserGeneral(userUp.general));
    dispatch(updateUserPreferences(userUp.preferences));

    userUpdateDebounceTimer = setTimeout(async () => {
      dispatch(updateUserGeneral(userUp.general));
      dispatch(updateUserPreferences(userUp.preferences));
      updateUserApi({ user_update: { ...userUpdate, ...data } });

      if (window && window.electron) {
        window.electron.analytics.setUser(userUp);
        changes.map((change) => {
          if (analyticsEvents.hasOwnProperty(change[0])) {
            sendAnalyticsEvent(analyticsEvents[change[0]], {
              value: change[1],
            });
          }
          return null;
        });
      }
    }, 2000);

    dispatch(notifyTrayAction());
  }
);

const analyticsEvents = {
  last_main_vol: 'main_vol_change',
  last_env_vol: 'env_vol_change',
  last_weather_vol: 'weather_vol_change',
  receive_emails: 'receive_emails',
  play_on_startup: 'auto_play',
  load_on_startup: 'launch_on_startup',
  temp_unit: 'temp_units',
  user_env: 'env_change',
};
