/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:1f04eadf-bf5d-4b54-b61d-5a3a8946ff78",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_7Zs1oZFLW",
  "aws_user_pools_web_client_id": "4vluucen9mpongh5nvrhqmj7q7",
  "oauth": {
    "domain": "auth.getsound.ai",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": process.env.NODE_ENV === 'development' ? "http://localhost:49391" : "https://affiliate.getsound.ai",
    "redirectSignOut": process.env.NODE_ENV === 'development' ? "http://localhost:49391" : "https://affiliate.getsound.ai",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [
    "FACEBOOK",
    "GOOGLE",
    "AMAZON",
    "APPLE"
  ],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
};


export default awsmobile;
