/* eslint-disable import/prefer-default-export */
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { isBetween } from '../platform-utils/utils';
import { RootState } from './store';
import { PaymentPlan } from './types';

export const playerData = (state: RootState) => state.player;
export const authData = (state: RootState) => state.auth;
export const userData = (state: RootState) => state.user;
export const appData = (state: RootState) => state.app;
export const statsData = (state: RootState) => state.stats;

export const getBrandData = createDraftSafeSelector(
  [appData, userData],
  (app, user) => ({
    paymentPlan: user.plan_info.current_plan,
    version: app.version,
  })
);

export const getInterfaceLocked = createDraftSafeSelector(
  [appData],
  (app) => app.interfaceLocked
);

export const getPlayerWatermarkData = createDraftSafeSelector(
  [playerData, userData],
  (player, user) => ({
    isPlaying: player.isPlaying,
    watermark: player.watermark,
    watermarkVolume: user.global_settings.watermark_volume,
    isFreePlan: user.plan_info.current_plan === PaymentPlan.Free,
  })
);

export const getNotificationsPortalData = createDraftSafeSelector(
  [appData],
  (app) => ({
    notifications: app.notifications,
    isTutorialPassed: app.isTutorialPassed,
    modalShown: app.modal.type !== null,
  })
);

export const getAbstractModalData = createDraftSafeSelector(
  [appData],
  (app) => ({
    fadeModal: app.fadeModal,
    modal: app.modal,
  })
);

export const getDebugInfo = createDraftSafeSelector(
  [appData, playerData, statsData],
  (app, player, stats) => ({
    showDebug: app.showDebug,
    playerStats: stats.playerStats,

    playerEnvRealVolume: player.playerEnvRealVolume,
    playerWeatherRealVolume: player.playerWeatherRealVolume,
    userWeatherSoundState: player.userWeatherSoundState,
  })
);

export const getDynamicBackgroundData = createDraftSafeSelector(
  [appData],
  (app) => ({
    isInitialLoading: app.isInitialLoading,
    background: app.background,
  })
);

export const getConnectionProblemModalData = createDraftSafeSelector(
  [playerData],
  (player) => ({
    isPlaying: player.isPlaying,
  })
);

export const getTutorialData = createDraftSafeSelector(
  [appData, playerData, userData],
  (app, player, user) => {
    return {
      isInitialLoading: app.isInitialLoading,
      isTutorialPassed: app.isTutorialPassed,
      menuOpen: app.menuOpen,
      tutorialEffects: app.tutorialEffects,
      userWeatherState: player.userWeatherState,
      userWeatherSoundState: player.userWeatherSoundState,
      runoutState: user.restrictions.usage_limit_reached,
    };
  }
);

export const getVolumeData = createDraftSafeSelector(
  [playerData],
  (player) => ({
    volume: player.volume,
    userWeatherSoundState: player.userWeatherSoundState,
  })
);

export const getAudioControlData = createDraftSafeSelector(
  [playerData, appData],
  (player, app) => {
    return {
      isPlaying: player.isPlaying,
      interfaceLocked: app.interfaceLocked,
      showZeroSoundNotification: app.showZeroSoundNotification,

      envActive: player.volume.env > 0.01,
      mainActive: player.volume.main > 0.01,

      weatherActive: player.userWeatherSoundState,
      weatherMuted: player.volume.weather < 0.02,
      volume: player.volume,

      volumeHidden: app.tutorialEffects?.volumeHidden && !app.isTutorialPassed,
    };
  }
);

export const getMenuAccountData = createDraftSafeSelector(
  [userData],
  (user) => ({
    stripeCustomerId: user.general.stripe_customer_id,
    currentPlan: user.plan_info.current_plan,
  })
);

export const getTerminateModalData = createDraftSafeSelector(
  [userData],
  (user) => ({
    currentMachines: user.current_machines,
    zonesAllowed: user.plan_info.zones_allowed,
    coupon: user.plan_info.coupon,
    currentPlan: user.plan_info.current_plan,
  })
);

export const getAddZonesModalData = createDraftSafeSelector(
  [userData],
  (user) => ({
    currentPlan: user.plan_info.current_plan,
    zonesAllowed: user.plan_info.zones_allowed,
    subscriptionInterval: user.plan_info.subscription_interval,
  })
);

export const getZeroSoundModalData = createDraftSafeSelector(
  [playerData, appData],
  (player, app) => ({
    mainVol: player.volume.main,
    isCrossfading: player.isCrossfading,
    isTutorial: !app.isTutorialPassed,
  })
);

export const getHomeVolumeData = createDraftSafeSelector(
  [playerData, appData],
  (player, app) => ({
    showHomeVolume: app.showHomeVolume,
    userWeatherSoundState: player.userWeatherSoundState,

    mainVolume: player.volume.main,
    envVolume: player.volume.env,
    weatherVolume: player.volume.weather,
  })
);

export const getWeatherPlayerData = createDraftSafeSelector(
  [playerData, userData],
  (player, user) => {
    return {
      isPlaying: player.isPlaying,
      userWeatherState: player.userWeatherState,
      userWeatherSoundState: player.userWeatherSoundState,
      weatherVolume: isBetween(player.volume.weather, 0, 1),
      weatherVolumeMultiplier: user.global_settings.max_weather_volume,
      weatherSync: user.preferences.weather_sync,
    };
  }
);

export const getEnvPlayerData = createDraftSafeSelector(
  [playerData, userData],
  (player, user) => {
    return {
      isPlaying: player.isPlaying,
      currentPlan: user.plan_info.current_plan,
      isEnvSelectPlaying: player.isEnvSelectPlaying,
      userEnvState: player.userEnvState,
      envLock: user.restrictions.env_locks,

      envVolume: isBetween(player.volume.env, 0, 1),
      envVolumeMultiplier: user.global_settings.max_env_volume,
    };
  }
);

export const getMainPlayerData = createDraftSafeSelector(
  [playerData, userData],
  (player, user) => {
    return {
      playlist: player.playlist,
      isPlaying: player.isPlaying,
      isMainPlaying: player.isMainPlaying,
      isForcedRefresh: player.isForcedRefresh,
      isRefreshingSound: player.isRefreshingSound,
      isCrossfading: player.isCrossfading,

      mainVolume: isBetween(player.volume.main, 0, 1),
      mainVolumeMultiplier: user.global_settings.max_main_volume,
    };
  }
);

export const getAppData = createDraftSafeSelector(
  [appData, authData],
  (app, auth) => {
    return {
      isLoggedIn: auth.isLoggedIn,
      deviceId: app.deviceId,
    };
  }
);

export const getEnvData = createDraftSafeSelector([userData], (user) => {
  return {
    envLock: user.restrictions.env_locks,
    userEnv: user.preferences.user_env,
    envVolume: user.preferences.last_env_vol,
    currentPlanName: user.plan_info.current_plan,
  };
});

export const getGlobeData = createDraftSafeSelector(
  [playerData, userData],
  (player, user) => {
    return {
      globeLock: user.restrictions.globe_locks,
      isRefreshingSound: player.isRefreshingSound,
      isCrossfading: player.isCrossfading,

      isRefreshAbuse: user.restrictions.refresh_abuse,
      currentPlanName: user.plan_info.current_plan,
    };
  }
);

export const getNavigationData = createDraftSafeSelector(
  [authData, appData],
  (auth, app) => {
    return {
      isLoggedIn: auth.isLoggedIn,
      authLoading: auth.authLoading,
      appUpdating: app.appUpdating,
    };
  }
);

export const getHomeControlsData = createDraftSafeSelector(
  [userData, appData, playerData],
  (user, app, player) => {
    return {
      currentPlanName: user.plan_info.current_plan,
      isRefreshAbuse:
        user.plan_info.current_plan === PaymentPlan.Free &&
        user.restrictions.refresh_abuse,

      interfaceLocked: app.interfaceLocked,
      isInitialLoading: app.isInitialLoading,
      isFirstTime: app.isFirstTime,
      controlsHidden:
        !app.isTutorialPassed && app.tutorialEffects?.controlsHidden,

      userCoordinates: app.userCoordinates,

      isRefreshingSound: player.isRefreshingSound,
      isPlaying: player.isPlaying,
      isCrossfading: player.isCrossfading,
      envPlayerActive: player.playerEnvRealVolume >= 0.02,
    };
  }
);
export const getHomeData = createDraftSafeSelector(
  [userData, appData, playerData],
  (user, app, player) => {
    return {
      currentPlanName: user.plan_info.current_plan,

      isRecentlyUpdatedPlan: app.recentlyUpdatedPlan,
      isFirstTime: !app.isTutorialPassed,
      menuHidden: !app.isTutorialPassed && app.tutorialEffects?.menuHidden,

      homeView: app.homeView,
      interfaceLocked: app.interfaceLocked,
      menuOpen: app.menuOpen,
      isInitialLoading: app.isInitialLoading,
      hideSplashStarted: app.hideSplashStarted,
      showZeroSoundNotification: app.showZeroSoundNotification,
      version: app.version,
      isPlaying: player.isPlaying,
    };
  }
);

export const getMenuData = createDraftSafeSelector(
  [userData, appData, playerData],
  (user, app, player) => {
    return {
      email: user.general.email,
      zonesAllowed: user.plan_info.zones_allowed,
      tempUnit: user.preferences.temp_unit,
      playOnStartup: user.preferences.play_on_startup,
      loadOnStartup: user.preferences.load_on_startup,
      receiveEmails: user.preferences.receive_emails,
      weatherSync: user.preferences.weather_sync,
      currentPlanName: user.plan_info.current_plan,
      currentMachinesCount: user.current_machines.length,
      profileImage: user.general.profile_picture,
      name: `${user.general.first_name} ${user.general.last_name}`,

      dailyUsage: user.restrictions.daily_usage,
      dailyUsageLimit: user.restrictions.daily_usage_limit,

      menuOpen: app.menuOpen,
      sideMenuDeal: app.interfaceData.sideMenuDeal,
      deviceName: app.deviceName,
      travelMode: app.travelMode,
      appVersion: app.version,

      isCrossfading: player.isCrossfading,
    };
  }
);

export const getVolumes = createDraftSafeSelector([playerData], (player) => {
  return {
    main: player.volume.main,
    env: player.volume.env,
    weather: player.volume.weather,
    weatherEnabled: player.userWeatherSoundState,
  };
});

export const getModalData = createDraftSafeSelector([appData], (app) => {
  return app.modal;
});

export const getWeatherInfoState = createDraftSafeSelector(
  [appData, userData, playerData],
  (app, user, player) => {
    return {
      interfaceLocked: app.interfaceLocked,
      interfaceData: app.interfaceData,
      locationPickerEnabled:
        app.locationPickerEnabled ||
        user.plan_info.current_plan !== PaymentPlan.Free,
      weatherHidden:
        app.tutorialEffects?.weatherHidden && !app.isTutorialPassed,

      isRefreshing: player.isRefreshingSound,
      userCoordinates: app.userCoordinates,
      currentCity: user.general.city,
      tempUnit: user.preferences.temp_unit,
      currentPlanName: user.plan_info.current_plan,
    };
  }
);
