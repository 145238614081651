import { FC, MouseEvent, ReactNode, useCallback } from 'react';
import './circle-button.scss';

interface CircleButtonProps {
  onClick?: () => void;
  isActive: boolean;
  type: 'button' | 'submit';
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
}

const CircleButton: FC<CircleButtonProps> = (props) => {
  const {
    onClick,
    disabled,
    className,
    isActive = false,
    type = 'button',
    children,
  } = props;

  const onMouseDown = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    return false;
  }, []);

  return (
    <button
      type={type}
      onMouseDown={onMouseDown}
      disabled={disabled}
      className={`circle-button ${isActive ? 'active' : ''} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CircleButton;
