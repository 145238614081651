import { FC } from 'react';
import FacebookIcon from '../../assets/images/icon-facebook.svg';
import GoogleIcon from '../../assets/images/icon-google-color.svg';
import AppleIcon from '../../assets/images/icon-apple-color.svg';
import AmazonIcon from '../../assets/images/icon-amazon-color.svg';
import useOpenIdLogin from '../../hooks/useOpenIdLogin';
import './social-login.scss';

interface SocialLoginProps {
  beforeLogin?: () => void;
  afterLogin?: () => void;
}

const SocialLogin: FC<SocialLoginProps> = (props) => {
  const { beforeLogin = null, afterLogin = null } = props;
  const { onSignInAmazon, onSignInApple, onSignInFacebook, onSignInGoogle } =
    useOpenIdLogin(beforeLogin, afterLogin);

  return (
    <div className="login-social">
      <div className="d-flex align-items-center justify-content-center mt-1 mb-1" style={{
        gap: "20px",
      }}>
        {/* <button
          type="button"
          className="social-btn"
          onClick={() => onSignInFacebook()}
        >
          <img src={FacebookIcon} alt="Facebook" width={80} height={80} />
        </button> */}
        <button
          type="button"
          className="social-btn icon-google"
          onClick={() => onSignInGoogle()}
        >
          {/* <img src={GoogleIcon} alt="Google" width={80} height={80} /> */}
        </button>
        <button
          type="button"
          className="social-btn icon-apple"
          onClick={() => onSignInApple()}
        >
          {/* <img
            src={AppleIcon}
            alt="Apple"
            width={80}
            height={80}
            style={{
              marginTop: '-4px',
            }}
          /> */}
        </button>
        <button
          type="button"
          className="social-btn icon-amazon"
          onClick={() => onSignInAmazon()}
        >
          {/* <img src={AmazonIcon} alt="Amazon" width={80} height={80} /> */}
        </button>
      </div>
    </div>
  );
};

export default SocialLogin;
