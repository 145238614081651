import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Amplify, Auth } from 'aws-amplify';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import awsconfig from './aws-exports';

import store from './redux/store';
import App from './App';

// Sentry.init({
//   dsn: 'https://ff1c58ecc09148bc84cad471280a8843@o4504100810194944.ingest.sentry.io/4504100813406209',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from 'constants/config';
import { getStoreDataSync, setStoreData } from 'platform-utils/utils';
import { StoreKey } from 'redux/store-keys';

// Replace YOUR_TOKEN with your Project Token
mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  ignore_dnt: true,
});

let deviceId = getStoreDataSync(StoreKey.DeviceId);
if (!deviceId) {
  deviceId = Math.random().toString(36) + Math.random().toString(36) + Math.random().toString(36);
  setStoreData(StoreKey.DeviceId, deviceId);
  mixpanel.identify(deviceId);
}

Amplify.configure(awsconfig);

// // Saving instances
// const oldWindowOpen = window.open;
// window.open = (url: string) => {
//   return oldWindowOpen(url);
// };

// Init app
const container = document.getElementById('root');
const root = createRoot(container);

container.addEventListener('mousedown', (event) => {
  if (event.target.nodeName === 'BUTTON') {
    event.target.blur();
  }
});



root.render(
  <Provider store={store}>
    <App />
  </Provider>
);