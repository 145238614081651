import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayerStateSlice, PlayersVolumes, PlayerType } from '../types';

const initialPlayerState: PlayerStateSlice = {
  activePlayer: 2,
  isPlaying: false,
  isMainPlaying: false,
  isEnvSelectPlaying: false,
  isForcedRefresh: false,
  isRefreshingSound: false,
  isCrossfading: false,

  playerEnvRealVolume: 0,
  playerWeatherRealVolume: 0,

  userEnvState: '',
  userWeatherState: '',
  userWeatherSoundState: false,
  watermark: false,

  mainVolume: 0.5,

  volume: {
    main: 0.5,
    env: 0.5,
    weather: 0.5,
  },
  volumeUpdateSource: null,

  playlist: [],
};

export const playerSlice = createSlice({
  name: 'player',
  initialState: initialPlayerState,
  reducers: {
    updateIsMainPlaying: (state, action) => {
      state.isMainPlaying = action.payload;
    },
    updateIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
      state.isMainPlaying = action.payload;
    },
    updateIsEnvPlaying: (state, action) => {
      state.isEnvSelectPlaying = action.payload;
    },
    setUserEnv: (state, action) => {
      state.userEnvState = action.payload;
    },
    updateUserEnvVolume: (state, action) => {
      state.userEnvVolume = action.payload;
    },
    updateUserWeatherState: (state, action) => {
      state.userWeatherState = action.payload;
    },
    updateUserWeatherSoundState: (state, action) => {
      state.userWeatherSoundState = action.payload;
    },
    updateRefreshing: (state, action) => {
      state.isRefreshingSound = action.payload;
    },
    toggleWatermark: (state, action) => {
      state.watermark = action.payload;
    },

    setMainVolume: (state, action) => {
      state.mainVolume = action.payload;
    },

    playerEnvRealVolume: (state, action) => {
      state.playerEnvRealVolume = action.payload;
    },
    playerWeatherRealVolume: (state, action) => {
      state.playerWeatherRealVolume = action.payload;
    },

    updatePlayerVolume: (state, action: PayloadAction<PlayersVolumes>) => {
      // const normalizedVolumes: PlayersVolumes = {};

      // [PlayerType.Main, PlayerType.Environment, PlayerType.Weather].forEach(
      //   (playerType) => {
      //     if (Object.keys(action.payload).includes(playerType)) {
      //       let value = 0.5;
      //       if (action.payload[playerType] < 0) value = 0;
      //       if (action.payload[playerType] > 1) value = 1;
      //       normalizedVolumes[playerType] = value;
      //     }
      //   }
      // );

      // if (action.payload.source) {
      //   normalizedVolumes.source = action.payload.source;
      // }

      state.volume = {
        ...state.volume,
        ...action.payload,
      };
    },
    pushPlaylist: (state, action) => {
      state.playlist = [...state.playlist, action.payload];
    },
    popPlaylist: (state) => {
      state.playlist = state.playlist.filter((_, i) => i !== 0);
    },

    setForcedRefresh: (state, action) => {
      state.isForcedRefresh = action.payload;
    },
    setIsCrossfading: (state, action) => {
      state.isCrossfading = action.payload;
    },
  },
});

export const {
  updateIsEnvPlaying,
  playerEnvRealVolume,
  playerWeatherRealVolume,
  setUserEnv,
  updateUserEnvVolume,
  updateUserWeatherState,
  updateUserWeatherSoundState,

  toggleWatermark,

  updateIsPlaying,
  updateIsMainPlaying,

  pushPlaylist,
  popPlaylist,
  updateRefreshing,
  setForcedRefresh,
  setIsCrossfading,
  updatePlayerVolume,
} = playerSlice.actions;

export default playerSlice.reducer;
