import "./admin-login-screen.scss";
import logo from '../assets/images/logo.svg';
import Input from "../components/input/Input";

import { GoMail } from "react-icons/go";
import { HiLockClosed } from "react-icons/hi";
import { useEffect, useState } from "react";
import CircleButton from "../components/circle-button/CircleButton";
import axios from "axios";
import { API_URL } from "../constants/const";
import { useNavigate } from "react-router-dom";
import { getStoreDataSync } from "platform-utils/utils";

interface LoginScreenProps {

}

const AdminLoginScreen = (props: LoginScreenProps) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const onChange = (field: string) => (value: string) => {

    setFormData(prev => ({ ...prev, [field]: value }))
  }

  useEffect(() => {
    const key = localStorage.getItem("key");

    if (key) {
      navigate("/admin/");
    }

  }, []);

  const onLogin = async (data: any) => {
    localStorage.setItem("key", data.password);
    navigate("/admin/");
  }

  const onSubmit = () => {
    setIsLoading(true);
    onLogin(formData);
  }

  return <div className="screen admin-login-screen">

    <div className="form">

      <div className="head">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          <div>Affiliates Admin</div>
        </h1>
      </div>

      <div className="input-block">
        {/* <Input value={formData.email} placeholder="Email" onChange={onChange("email")} type={"text"} icon={<GoMail />} /> */}
        <Input value={formData.password} placeholder="Password" onChange={onChange("password")} type={"password"} icon={<HiLockClosed />} />

      </div>

      {error && <div className="error">
        {error}
      </div>}

      <div className="submit">
        <CircleButton type="submit" isActive={isLoading} onClick={onSubmit} />
      </div>
    </div>
  </div>

}

export default AdminLoginScreen;