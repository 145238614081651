import { FC, useRef, lazy, Suspense } from 'react';
import { getModalData } from '../../redux/selectors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ModalType } from '../../redux/types';
// import PaymentModal from './payments-modal/PaymentModal';
// import ZeroVolumeModal from './zero-volume-modal/ZeroVolumeModal';
import hideModalAction from '../../redux/actions/hideModalAction';
import { sendAnalyticsEvent } from '../../platform-utils/utils';

const ModalService: FC = () => {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector(getModalData);

  const Modals = useRef({
    [ModalType.Runout]: {
      component: lazy(() => import('./runout-modal/RunoutModalNew')),
      options: {
        close: false,
      },
    },
    [ModalType.Quiz]: { //done
      component: lazy(() => import('./quiz-modal/QuizModal')),
      options: {
        close: true,
      },
    },
    [ModalType.Terminate]: { //done
      component: lazy(() => import('./terminate-modal/TerminateModal')),
      options: {
        close: false,
      },
    },
    [ModalType.ConnectionProblem]: { //done
      component: lazy(
        () => import('./connection-problem-modal/ConnectionProblemModal')
      ),
      options: {
        close: true,
        overlay: false,
      },
    },
    [ModalType.ServerUpgrading]: {
      component: lazy(
        () => import('./server-upgrade-modal/ServerUpgradeModal')
      ),
      options: {
        close: false,
        overlay: false,
      },
    },
    [ModalType.UserRefresh]: { //done
      component: lazy(() => import('./refresh-modal/UserRefreshModal')),
      options: {
        close: true,
      },
    },
    [ModalType.NoLocation]: {
      component: lazy(() => import('./no-location-modal/NoLocationModal')),
      options: {
        close: true,
      },
    },
    // [ModalType.Payment]: {
    //   component:lazy(() => import('./terminate-modal/TerminateModal')),
    //   options: {
    //     close: true,
    //   },
    // },
    // [ModalType.ZeroVolume]: {
    //   component: lazy(() => import('./terminate-modal/TerminateModal')),
    //   options: {
    //     close: true,
    //   },
    // },
    [ModalType.AddZones]: {
      component: lazy(() => import('./add-zone-modal/AddZonesModal')),
      options: {
        close: true,
      },
    },
    [ModalType.ContactUs]: {
      component: lazy(() => import('./contact-us-modal/ContactUsModal')),
      options: {
        close: true,
      },
    },
  });

  const onClose = () => {
    dispatch(hideModalAction(null));
  };

  if (!type) return null;

  if ([ModalType.ServerUpgrading].indexOf(type) < 0) {
    sendAnalyticsEvent(type);
  }

  const modalProps = {
    onClose: Modals.current[type].options.close ? onClose : () => {},
    overlay: Modals.current[type].options.overlay ?? true,
  };

  const ModalComponent = Modals.current[type].component;

  return (
    <Suspense>
      <ModalComponent {...modalProps} />
    </Suspense>
  );
};

export default ModalService;
