import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { pushPlaylist, updateIsPlaying } from '../reducers/playerSlice';
import { setInitialLoading, showSplash } from '../reducers/appSlice';
import { StoreKey } from '../store-keys';
import { PaymentPlan } from '../types';
import { getStoreData } from '../../platform-utils/utils';
import { ASSETS_PATH } from '../../constants/config';

export default createAsyncThunk<any, void, { state: RootState }>(
  'app/loadStubTracks',
  async (_, { dispatch }) => {
    const isFreePlan = await getStoreData(StoreKey.PaymentPlan);
    const PATH = `${ASSETS_PATH}sounds/local-tracks/`;
    const soundscape = {
      main:
        isFreePlan === PaymentPlan.Free
          ? `${PATH}no_internet_free_account.ogg`
          : `${PATH}no_internet_unlimited_account.ogg`,
    };

    dispatch(pushPlaylist(soundscape));

    dispatch(updateIsPlaying(true));
    dispatch(showSplash(false));
    dispatch(setInitialLoading(false));
  }
);
