import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Register.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { MdMail } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { HiLockClosed, HiOutlineMail } from 'react-icons/hi';
import { TERMS_LINK, PRIVACY_LINK } from '../../../constants/links';
import {
  confirmSignup,
  sendEmailVerification,
  signUp,
} from '../../../api/auth-api';
import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX } from '../../../constants';
import SocialLogin from '../SocialLogin';
import CircleButton from '../../../components/common/circle-button/CircleButton';
import ConfirmCode from '../ConfirmCode';
import Input from '../../../components/common/input/Input';
import Logo from "../../../assets/images/icon-logo-white.svg";
import IntroLogo from "../../../assets/images/intro-logo.png";
import Button from 'components/common/abstract-button/Button';
import ConfirmCodeInline from '../ConfirmCodeInline';
import { isDesktop } from 'react-device-detect';

interface IFormInputs {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
  termsOfUse: boolean;
}

// const formSchema = Yup.object().shape({
//   name: Yup.string(),
//   email: Yup.string()
//     .required('Email is required')
//     .matches(EMAIL_REGEX, 'Please enter a valid email'),
//   password: Yup.string()
//     .required('Password is required')
//     .matches(
//       PASSWORD_REGEX,
//       'At least 8 characters, 1 uppercase, 1 lowercase, 1 number'
//     )
//     .min(
//       8,
//       'At least 8 characters, 1 uppercase, 1 lowercase, 1 number'
//     ),
//   repeatPassword: Yup.string().oneOf(
//     [Yup.ref('password')],
//     'Passwords does not match'
//   ),
//   termsOfUse: Yup.boolean()
//     .oneOf([true], 'Please read and accept terms of use')
//     .default(false),
// });

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState({
    email: location.state?.email || '',
    password: "",
  })

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [regSuccess, setRegSuccess] = useState(false);

  const [signupError, setSignUpError] = useState(false);
  const [resendMessage, setResendMessage] = useState<string | null>(null);
  const [signupErrorMessage, setSignUpErrorMessage] = useState<
    string | undefined
  >();
  const [status, setStatus] = useState(
    'Please check your email inbox for code'
  );
  const [successError, setSuccessError] = useState();

  const registerSubmit = async () => {
    setSignUpError(null);
    setIsLoading('main');
    // Splitting names in two part

    // Preparing final POST object
    const finalFormData = {
      first_name: '',
      last_name: '',
      email: userData.email.toLowerCase(),
      password: userData.password,
    };

    try {
      const response = await signUp(finalFormData);
      console.log(response);
      setIsLoading(null);
      if (response.success) {
        console.log('Registration success!');
        setRegSuccess(true);
      } else {
        console.log('error signing up');
        setIsLoading(null);
        setSignUpError(true);
        if (response.code === 'UsernameExistsException') {
          setSignUpErrorMessage('User with this email already exists');
          // navigate('/login', {
          //   state: {
          //     error: true,
          //     email: finalFormData.email,
          //     code: response.code,
          //     message: response.message,
          //   },
          // });
        }
        setSignUpErrorMessage(response.message);
      }
    } catch (e) {
      console.log('error signing up', e);
    }
  };

  const onSendAgain = async () => {
    const res = await sendEmailVerification(userData.email);
    setSignUpError(!res.success);
    setSignUpErrorMessage(
      res.success ? 'Verification email has been sent' : res.message
    );
  };

  const onBack = () => {
    navigate('/login', {
      state: {
        email: userData.email,
      },
    });
  };


  const [codeStatus, setCodeStatus] = useState('');

  const afterLogin = () => {
    navigate("/affiliate");
  }

  const [showLoading, setShowLoading] = useState(false);

  const onCode = useCallback(
    async (code: string) => {
      setCodeStatus('Validating...');
      setSignUpError(false);
      setIsLoading(true);

      const res = await confirmSignup({
        email: userData.email.toLowerCase(),
        code,
      });
      if (res?.success) {

        setCodeStatus('Success! Logging in...');
        setShowLoading(true);
        Auth.signIn(userData.email.toLowerCase(), userData.password).then(() => {
          navigate("/affiliate")
        });

      } else {
        setSignUpError(true);
        setCodeStatus('Please check your email inbox for code');
        setSignUpErrorMessage(res.message);
      }
      setIsLoading(false);
    },
    [userData]
  );


  const submitDisabled = !PASSWORD_REGEX.test(userData.password) || !EMAIL_REGEX.test(userData.email);

  return (
    <div className="register-screen">
      {showLoading && <div className="loading">
        <div className="spinner"></div>
      </div>
      }

      <div className="sideblock">
        <div className="header">
          <a href="#" onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}>
            <img src={IntroLogo} alt="" className='intro-logo' />
          </a>
        </div>


        <h1 className="font-light text-3xl" style={{
          margin: "40px 0 50px"
        }}>Create Account</h1>

        {!regSuccess && <>
          <div className='flex flex-row justify-between align-items-center'>
            <div className='font-light text-xl'>With socials</div>

            <SocialLogin afterLogin={afterLogin} />
          </div>

          <div className="or-separator" style={{
            margin: "10px 0 10px"
          }}>or</div>
        </>}



        {/* The Form */}
        <div className="register-form">
          {/* Full Name */}
          <div className="form">
            {/* <Input
                    icon={<BsFillPersonFill color="#fff" size={18} />}
                    type="text"
                    inputProps={register('name')}
                    placeholder="Full Name"
                    inputStyle="form-in"
                    error={errors.name}
                  />

                  {errors.name ? (
                    <div className="form-error-text">
                      {errors.name.message}
                    </div>
                  ) : null} */}

            <Input
              icon={<MdMail color="#808080" size={18} />}
              type="text"
              // inputProps={register('email', {
              //   value: userEmail,
              //   onChange: (e) => {
              //     setUserEmail(e.target.value);
              //   },
              // })}
              value={userData.email}
              disabled={regSuccess}
              onChange={(value) => {
                setUserData(prev => ({ ...prev, email: value }))
              }}
              placeholder="Email"
              inputStyle="form-in"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  registerSubmit();
                }
              }}
            />

            {/* Password */}
            <Input
              icon={<HiLockClosed color="#808080" size={18} />}
              type="password"
              value={userData.password}
              disabled={regSuccess}

              onChange={(value) => {
                setUserData(prev => ({ ...prev, password: value }))
              }}
              placeholder="Password"
              inputStyle="form-in"
              variant='dark'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  registerSubmit();
                }
              }}
            />

            {/* {error && <div className="error">{error}</div>} */}


            {!regSuccess && <ul className='validation'>
              <li className={userData.password.length >= 8 ? "valid" : "invalid"} >At least 8 characters</li>
              <li className={/[0-9]/.test(userData.password) ? "valid" : "invalid"}>At least one number (0-9)</li>
              <li className={/[a-z]/.test(userData.password) ? "valid" : "invalid"}>At least one lowercase</li>
              <li className={/[A-Z]/.test(userData.password) ? "valid" : "invalid"}>At least one uppercase</li>
            </ul>}

          </div>

          {/* <div className=" terms">
                <div className="checkbox">
                  <input
                    id="termsOfUse"
                    type="checkbox"
                  // className={errors.termsOfUse ? 'error' : ''}
                  />
                  <label htmlFor="termsOfUse">
                    I agree with{' '}
                    <a href="#" onClick={() => window.open(TERMS_LINK)}>
                      Terms Of Use
                    </a>
                    <span> and </span>
                    <a href="#" onClick={() => window.open(PRIVACY_LINK)}>
                      Privacy policy
                    </a>
                  </label>
                </div>


              </div> */}

          {/* Form Submit */}
          {!regSuccess && <div className="register-form-submit">
            <Button onClick={registerSubmit} disabled={submitDisabled || !!isLoading} type='submit' variant='green' isActive={isLoading === 'main'} >Continue</Button>
          </div>}

          {regSuccess && <div>
            <ConfirmCodeInline
              email={userData.email}
              onCode={onCode}
              onBack={onBack}
              isLoading={!!isLoading}
              onSendAgain={onSendAgain}
              errorMessage={codeStatus}
            />
          </div>
          }

          {signupError ? (
            <div className="signup-error">{signupErrorMessage}</div>
          ) : null}

          <div className="terms text-l" >
            By creating an account, you agree to our <a href="https://getsound.ai/terms.html" target='_blank'>General Terms and
              Conditions</a> and have read and acknowledge the <a href="https://getsound.ai/privacy.html" target='_blank'>Privacy policy</a>
          </div>

          <div className="back-to-login">
            Already have an account? <a onClick={onBack}>Sign In</a>
          </div>



        </div>





      </div>
    </div>
  );
};

export default Register;
