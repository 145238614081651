import BANGKOK from '../assets/images/globes/bangkok.jpg';
import LONDON from '../assets/images/globes/london.jpg';
import BEIJING from '../assets/images/globes/beijing.jpg';
import ISTANBUL from '../assets/images/globes/istanbul.jpg';
import NEW_YORK from '../assets/images/globes/new_york.jpg';
import OSAKA from '../assets/images/globes/osaka.jpg';
import PARIS from '../assets/images/globes/paris.jpg';
import TEL_AVIV from '../assets/images/globes/tel_aviv.jpg';
import WATERFALL from '../assets/images/envs/waterfall.jpg';
import CAVE from '../assets/images/envs/cave.jpg';
import FOREST from '../assets/images/envs/forest.jpg';
import GRASSLAND from '../assets/images/envs/grassland.jpg';
import DESERT from '../assets/images/envs/desert.jpg';
import LAKE from '../assets/images/envs/lake.jpg';
import OCEANSIDE from '../assets/images/envs/oceanside.jpg';
import RIVER from '../assets/images/envs/river.jpg';
import UNDERWATER from '../assets/images/envs/underwater.jpg';
import MORNING_MOOD from '../assets/images/envs/MorningMood.jpg';
import AIRPLANE_CABIN from '../assets/images/envs/AirplaneCabin.jpg';
import BLIZZARD_HOWLING_WINDS from '../assets/images/envs/BlizzardHowlingWinds.jpg';
import CAMPFIRE from '../assets/images/envs/Campfire.jpg';
import COFFEE_SHOP from '../assets/images/envs/Coffeeshop.jpg';
import FIREPLACE from '../assets/images/envs/fireplace.jpg';
import GENTLE_RAIN from '../assets/images/envs/GentleRain.jpg';
import GENTLE_WIND from '../assets/images/envs/GentleWind.jpg';
import JUNGLE from '../assets/images/envs/jungle.jpg';
import NIGHT_MOOD from '../assets/images/envs/NightMood.jpg';
import OFFICE from '../assets/images/envs/Office.jpg';
import THUNDERSTORM from '../assets/images/envs/Thunderstorm.jpg';
import TRAIN_RIDE from '../assets/images/envs/TrainRide.jpg';
import URBAN from '../assets/images/envs/Urban.jpg';
import WIND_CHIMES_BELLS from '../assets/images/envs/WindchimesBells.jpg';
// import E_OCEAN from '../../../assets/sounds/env/ocean.ogg';
// import E_RIVER from '../../../assets/sounds/env/river.ogg';
// import E_WATERFALL from '../../../assets/sounds/env/waterfall.ogg';
// import E_LAKE from '../../../assets/sounds/env/lake.ogg';
// import E_UNDERWATER from '../../../assets/sounds/env/underwater.ogg';
// import E_URBAN from '../../../assets/sounds/env/urban.ogg';
// import E_COFFEE_SHOP from '../../../assets/sounds/env/coffeeshop.ogg';
// import E_FIREPLACE from '../../../assets/sounds/env/fireplace.ogg';
// import E_GENTLE_RAIN from '../../../assets/sounds/env/gentle_rain.ogg';
// import E_GENTLE_WIND from '../../../assets/sounds/env/gentle_wind.ogg';
// import E_JUNGLE from '../../../assets/sounds/env/jungle.ogg';
// import E_NIGHT_MOOD from '../../../assets/sounds/env/night_mood.ogg';
// import E_OFFICE from '../../../assets/sounds/env/office.ogg';
// import E_THUNDERSTORM from '../../../assets/sounds/env/thunderstorm.ogg';
// import E_TRAIN_RIDE from '../../../assets/sounds/env/train_ride.ogg';
// import E_WIND_CHIMES_BELLS from '../../../assets/sounds/env/windchime_bells.ogg';
// import E_CAMPFIRE from '../../../assets/sounds/env/campfire.ogg';
// import E_BLIZZARD_AND_HOWLING_WINDS from '../../../assets/sounds/env/blizzard_and_howling_winds.ogg';
// import E_AIRPLANE_CABIN from '../../../assets/sounds/env/airplane_cabin.ogg';
// import E_MORNING_MOOD from '../../../assets/sounds/env/morning_mood.ogg';
// import E_DESERT from '../../../assets/sounds/env/desert.ogg';
// import E_GRASSLAND from '../../../assets/sounds/env/grassland.ogg';
// import E_FOREST from '../../../assets/sounds/env/forest.ogg';
// import E_CAVE from '../../../assets/sounds/env/cave.ogg';
import { EnvPaidType, IEnvState } from '../redux/types';

export interface IGlobeState {
  location: string;
  lat: number;
  lng: number;
  text: string;
  image: string;
}

export const GLOBE_COORDINATES: IGlobeState[] = [
  {
    location: 'bangkok',
    lat: 13.7563,
    lng: 100.5018,
    text: 'live weather-reactive soundscape',
    image: BANGKOK,
  },
  {
    location: 'london',
    lat: 51.5072,
    lng: 0.1276,
    text: 'live weather-reactive soundscape',
    image: LONDON,
  },
  {
    location: 'beijing',
    lat: 39.9075,
    lng: 116.39723,
    text: 'live weather-reactive soundscape',
    image: BEIJING,
  },
  {
    location: 'istanbul',
    lat: 41.0082,
    lng: 28.9784,
    text: 'live weather-reactive soundscape',
    image: ISTANBUL,
  },
  {
    location: 'new york',
    lat: 40.73061,
    lng: -73.935242,
    text: 'live weather-reactive soundscape',
    image: NEW_YORK,
  },
  {
    location: 'osaka',
    lat: 34.6937,
    lng: 135.5023,
    text: 'live weather-reactive soundscape',
    image: OSAKA,
  },
  {
    location: 'paris',
    lat: 48.8566,
    lng: 2.3522,
    text: 'live weather-reactive soundscape',
    image: PARIS,
  },
  {
    location: 'tel aviv',
    lat: 32.0853,
    lng: 34.7818,
    text: 'live weather-reactive soundscape',
    image: TEL_AVIV,
  },
];

const ENVS: IEnvState[] = [
  // WATER
  {
    state: 'ocean',
    env: 'ocean',
    text: 'South side ocean, Thailand',
    image: OCEANSIDE,
    sound: 'ocean.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'river',
    env: 'river',
    text: 'SUWANNEE RIVER STREAM, FLORIDA',
    image: RIVER,
    sound: 'river.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'waterfall',
    env: 'waterfall',
    text: 'Yosemite Falls',
    image: WATERFALL,
    sound: 'waterfall.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'lake',
    env: 'lake',
    text: 'Lake Bled, Germany',
    image: LAKE,
    sound: 'lake.ogg',
    order: -2,
    type: EnvPaidType.Free,
  },
  {
    state: 'underwater',
    env: 'underwater',
    text: 'SCUBA DIVING IN INDIA',
    image: UNDERWATER,
    sound: 'underwater.ogg',
    type: EnvPaidType.Paid,
  },
  // END WATER
  {
    state: 'urban',
    env: 'urban',
    text: 'PARIZSKA STREET, PRAGUE',
    image: URBAN,
    sound: 'urban.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'coffee_shop',
    env: 'coffee shop',
    text: 'BAR ITALIA, SOHO',
    image: COFFEE_SHOP,
    sound: 'coffeeshop.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'office',
    env: 'office',
    text: 'OPEN WORKSPACE, London',
    image: OFFICE,
    sound: 'office.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'train_ride',
    env: 'train ride',
    text: 'RHINE VALLEY LINE, GERMANY',
    image: TRAIN_RIDE,
    sound: 'train_ride.ogg',
    type: EnvPaidType.Paid,
  },

  {
    state: 'airplane_cabin',
    env: 'airplane cabin',
    text: 'BOEING 737, Over The Atlantic',
    image: AIRPLANE_CABIN,
    sound: 'airplane_cabin.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'cave',
    env: 'cave',
    text: 'UNDERWATER SEA CAVE,  SARDINIA',
    image: CAVE,
    sound: 'cave.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'fireplace',
    env: 'fireplace',
    text: 'OLD MANSION, LIVINGROOM FIREPLACE, GERMANY',
    image: FIREPLACE,
    sound: 'fireplace.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'campfire',
    env: 'campfire',
    text: 'CAMP FACILITY, COLORADO',
    image: CAMPFIRE,
    sound: 'campfire.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'jungle',
    env: 'jungle',
    text: 'SINHARAJA FOREST RESERVE, SRI LANKA',
    image: JUNGLE,
    sound: 'jungle.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'grassland',
    env: 'grassland',
    text: 'GRACIOUS BARN, HOUSTON, TEXAS',
    image: GRASSLAND,
    sound: 'grassland.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'forest',
    env: 'forest',
    text: 'VALDIVIAN RAINFORESTA , CHILE',
    image: FOREST,
    sound: 'forest.ogg',
    order: -3,
    type: EnvPaidType.Free,
  },
  {
    state: 'desert',
    env: 'desert',
    text: 'SAHARA DESERT - THE AFRICAN CONTINENT',
    image: DESERT,
    sound: 'desert.ogg',
    type: EnvPaidType.Paid,
  },

  {
    state: 'windy_blizzard',
    env: 'windy blizzard',
    text: 'SOFT SNOWSTORM, ALASKA',
    image: BLIZZARD_HOWLING_WINDS,
    sound: 'blizzard_and_howling_winds.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'gentle_rain',
    env: 'gentle rain',
    text: 'Karl Johans gate, Oslo, Norway',
    image: GENTLE_RAIN,
    sound: 'gentle_rain.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'night_mode',
    env: 'night mood',
    text: 'CALMSLEY HILL CITY FARM - SYDNEY, AUSTRALIA',
    image: NIGHT_MOOD,
    sound: 'night_mood.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'morning_mood',
    env: 'morning mood',
    text: 'EARLY MORNING IN ZERMATT, SWITZERLAND',
    image: MORNING_MOOD,
    sound: 'morning_mood.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'thunderstorm',
    env: 'thunderstorm',
    text: 'SOFT THUNDERSTORMS, BALTIC SEA',
    image: THUNDERSTORM,
    sound: 'thunderstorm.ogg',
    type: EnvPaidType.Paid,
  },
  {
    state: 'gentle_wind',
    env: 'gentle wind',
    text: 'AN AMERICAN SOUNDSCAPE, MOJAVE',
    image: GENTLE_WIND,
    sound: 'gentle_wind.ogg',
    type: EnvPaidType.Paid,
  },

  {
    state: 'wind_chimes',
    env: 'wind chimes',
    text: 'KOSHI CHIMES, JAPAN',
    image: WIND_CHIMES_BELLS,
    sound: 'windchime_bells.ogg',
    order: -1,
    type: EnvPaidType.Free,
  },
];

export const ENV_STATES = [
  ...ENVS.filter((env) => env.order).sort((a, b) =>
    a.order > b.order ? 1 : -1
  ),
  ...ENVS.filter((env) => !env.order),
];
