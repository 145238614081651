import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { errorLog } from '../../api/api';
import { StoreKey } from '../store-keys';
import { getStoreData } from '../../platform-utils/utils';

let recentErrors: string[] = [];

export default createAsyncThunk<any, any, { state: RootState }>(
  'app/errorLog',
  async (error, { getState }) => {
    try {
      const state = getState();
      const version = await getStoreData(StoreKey.AppVersion);
      const errorMsg = `${
        typeof error === 'string' ? error : error.type
      }, v${version}`;

      if (!recentErrors.includes(errorMsg)) {
        const payload = { ...state, errorData: null, metrics: null };
        if (typeof error !== 'string') {
          payload.errorData = error.payload;
        }
        payload.metrics = await getStoreData(StoreKey.Metrics);

        errorLog(errorMsg, payload);
        recentErrors.push(errorMsg);
        setTimeout(() => {
          recentErrors = recentErrors.filter((e) => e !== errorMsg);
        }, 5 * 60 * 1000);
      }
    } catch (e) {
      console.log(e);
    }
  }
);
