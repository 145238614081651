// eslint-disable-next-line import/prefer-default-export
export enum StoreKey {
  AppData = 'app-data',
  AppVersion = 'app-version',
  AssetVersion = 'asset-version',
  Autostart = 'autostart',
  Autoplay = 'autoplay',
  TutorialPassed = 'tutorial-passed',

  Location = 'location',

  IsDebug = 'is-debug',

  UserData = 'user-data',

  Zones = 'zones',
  ZoneName = 'zone-name',

  ClientIp = 'client-ip',
  UserId = 'user-id',
  PaymentPlan = 'payment-plan',
  GoogleClientId = 'google-client-id',
  DeviceId = 'device-id',

  Metrics = 'metrics',

  WindowSize = 'window-size',
  WindowPosition = 'window-position',

  UserGlobe = 'user-globe',
  ShowRefreshModal = 'show-refresh-modal',

  AppRestartedBecauseOfUpdate = 'app-restarted-bacause-of-update',
  PlayerIsPlaying = 'player-is-playing',

  UpdateCheckIntervalMin = 'update_check_interval_min',

  PaymentNotCompleted = 'payment-not-completed',
  SocialAffiliate = "social-affiliate",

  Referral = 'ref'
}
