import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom';
// import Welcome from '../screens/welcome/Welcome';
import Player from '../players/Player';
import PlayerEnv from '../players/PlayerEnv';
import PlayerWatermark from '../players/PlayerWatermark';
import PlayerWeather from '../players/PlayerWeather';
import Login from '../screens/login/Login';
import Register from '../screens/login/register/Register';
import Forgot from '../screens/login/forgot/Forgot';
// import Home from '../screens/home/Home';
import NotificationsPortal from './notification/NotificationsPortal';
// import SplashScreen from '../screens/splash-screen/SplashScreen';
import { useAppSelector } from '../redux/store';
import { getNavigationData } from '../redux/selectors';

import AffiliateScreen from 'screens/AffiliateScreen';
import AffiliateAdminScreen from 'screens/AffiliateScreenAdmin';
import AdminLoginScreen from 'screens/AdminLoginScreen';

const AppNavigation = () => {
  const { authLoading, isLoggedIn } =
    useAppSelector(getNavigationData);

  if (authLoading) {
    return <div className="loading">
      <div className="spinner"></div>
    </div>
  }



  return (
    <div className={`app-container `}>
      <Router>
        <Routes >
          <Route path="/" element={<Start />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/register" element={<Register />} />

          <Route path="/affiliate" element={<AffiliateScreen />} />

          <Route path="/admin/login" element={<AdminLoginScreen />} />
          <Route path="/admin/" element={<AffiliateAdminScreen />} />

          {/* <Route path="/intro" element={<Intro />} /> */}
          {/* <Route path="/download-screen" element={<DownloadScreen />} /> */}
          {/* <Route path="/home" element={<Home />} /> */}

          <Route path="*" element={<Navigate to="/login"/>} />
        </Routes>
      </Router>

      {/* <Player />
      <PlayerEnv />
      <PlayerWeather />
      <PlayerWatermark /> */}
    </div>
  );
};

const Start = () => {

  const { isLoggedIn } =
    useAppSelector(getNavigationData);

  console.log(isLoggedIn, "isLoggedIn");
  // debugger;

  if (isLoggedIn) {
    return <Navigate to="/affiliate" />
  } else {
    return <Navigate to="/login" />
  }


}


export default AppNavigation;

function getQueryParameter(path: string) {
  if (path === "/") return false;

  localStorage.setItem("ref", path.substring(1));

  return true;
}

function getURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object

  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        const value = pars[i].split("=");
        localStorage.setItem("ref", value[1]);
        return value;


        // localStorage.setItem('ref', ) 
        pars.splice(i, 1);
      }
    }

    return false;
  }
  return false;
}

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        const value = pars[i].split("=");
        localStorage.setItem("ref", value[1]);

        // localStorage.setItem('ref', ) 
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}