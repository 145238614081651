import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStateSlice } from '../types';

export const initialAuthState: AuthStateSlice = {
  authLoading: true,
  isLoggedIn: false,
  userTokenData: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    userLoginCheck: (state, action) => {
      const appData = action.payload;
      // debugger
      if (appData && appData.userTokenData) {
        state.authLoading = false;
        state.isLoggedIn = true;
        state.userTokenData = appData.userTokenData;
      } else {
        state.authLoading = false;
        state.isLoggedIn = false;
        state.userTokenData = null;
      }
    },
    authLoadingState: (state, action: PayloadAction<boolean>) => {
      state.authLoading = action.payload;
    },
    updateUserTokenData: (state, action) => {
      state.userTokenData = {
        ...state.userTokenData,
        ...action.payload,
      };
    },
  },
});

export const { userLoginCheck, authLoadingState, updateUserTokenData } =
  authSlice.actions;

export default authSlice.reducer;
