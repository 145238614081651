import './Login.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';

import { BsFillPersonFill } from 'react-icons/bs';
import { HiLockClosed } from 'react-icons/hi';
import { MdMail } from 'react-icons/md';
import { StoreKey } from '../../redux/store-keys';
import Input from '../../components/common/input/Input';
import { AnalyticsEvent } from '../../redux/service/analytics-events';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../constants';
import SocialLogin from './SocialLogin';
import CircleButton from '../../components/common/circle-button/CircleButton';
import Button from '../../components/common/abstract-button/Button';
import { confirmSignup, sendEmailVerification } from '../../api/auth-api';
import { sendAnalyticsEvent, setStoreData } from '../../platform-utils/utils';
import ConfirmCode from './ConfirmCode';
import Logo from "../../assets/images/icon-logo-white.svg";
import IntroLogo from "../../assets/images/intro-logo.png";
import { isDesktop } from 'react-device-detect';


interface IFormInputs {
  email: string;
  password: string;
}

const LoginErrorMessages = {
  UserNotConfirmedException: {
    text: 'User is not confirmed',
  },
};

const Login = () => {
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<IFormInputs>({ mode: 'onSubmit' });
  const navigate = useNavigate();
  const location = useLocation();

  const [loginError, setLoginError] = useState(
    !!(location.state && location.state.error)
  );
  const [loginErrorMessage, setLoginErrorMessage] = useState(
    location.state && location.state.error ? location.state.message : ''
  );
  const [loginErrorCode, setLoginErrorCode] = useState(
    location.state && location.state.error ? location.state.code : ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const userEmail = useRef(location.state ? location.state.email : '');
  const userPassword = useRef(location.state ? location.state.password : '');
  const [status, setStatus] = useState();
  const [successError, setSuccessError] = useState('');
  const [isNotConfrmed, setIsNotConfirmed] = useState(false);

  const [showDownload, setShowDownload] = useState(false);

  const afterLogin = () => {
    if (isDesktop) {
      navigate("/affiliate");
      setIsLoading(false);
    } else {
      navigate("/download-screen");
    }
  }

  const [userData, setUserData] = useState({
    email: "",
    password: ""
  });


  const loginSubmit = async () => {
    setIsLoading(true);
    setLoginError(false);

    if (!EMAIL_REGEX.test(userData.email)) {
      setLoginError(true);
      setLoginErrorMessage('Please enter a valid email');
      setIsLoading(false);
      return;
    }

    if (!PASSWORD_REGEX.test(userData.password)) {
      setLoginError(true);
      setLoginErrorMessage(
        'Password should include at least 8 characters, 1 uppercase, 1 lowercase, 1 number'
      );
      setIsLoading(false);
      return;
    }

    try {
      Auth.signIn(
        userData.email.toLowerCase(),
        userData.password
      ).then(() => {
        navigate("/affiliate");
      })
        .catch((err) => {
          setIsLoading(false);
          setLoginError(true);
          setLoginErrorMessage(err.message);
        });

      setStoreData(StoreKey.IsSocialLogin, false);

      userEmail.current = userData.email.toLowerCase();
      userPassword.current = userData.password;


    } catch (e: any) {
      userEmail.current = userData.email.toLowerCase();
      setIsLoading(false);

      setLoginError(true);

      if (e.code === 'UserNotConfirmedException') {
        setIsNotConfirmed(true);
        // setLoginErrorCode(e.code);
        setStatus('Please check your email inbox for code');
        await sendEmailVerification(userEmail.current);
      } else {
        setLoginErrorMessage(e.message);
      }
    }
  };

  const onForgotPassword = useCallback(() => {
    navigate('/forgot-password', {
      state: {
        email: userEmail.current,
      },
    });
  }, []);

  const onRegister = useCallback(() => {
    navigate('/register', {
      state: {
        email: userEmail.current,
      },
    });
  }, []);

  const onSendAgain = useCallback(async () => {
    const res = await sendEmailVerification(userEmail.current);
  }, []);

  const [codeStatus, setCodeStatus] = useState('');

  const onCode = async (code: string) => {
    const res = await confirmSignup({
      email: userEmail.current.toLowerCase(),
      code,
    });

    if (res?.success) {
      setCodeStatus('Logging in...');
      Auth.signIn(
        userEmail.current,
        userPassword.current
      ).then(() => {
        navigate("/home");
      });
    } else {
      setSuccessError(res.message);
      setCodeStatus('Please check your email inbox for code');
    }
  };

  const onBack = () => {
    setIsNotConfirmed(false);
  };

  const submitDisabled = !PASSWORD_REGEX.test(userData.password) || !EMAIL_REGEX.test(userData.email);

  return (
    <div className="login-screen">
      {/* <div className="login-screen-wrapper mx-auto text-center"> */}
      {/* Login Header */}

      <div className="sideblock">
        <div className="header">
          <a href="#" onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}>
            <img src={IntroLogo} alt="" className='intro-logo' />
          </a>
        </div>


        <h1 className="font-light text-3xl" style={{
          margin: "40px 0 50px"
        }}>GetSound Affiliates</h1>

        <div className='flex flex-row justify-between align-items-center'>
          <div className='font-light text-xl'>Log In With</div>

          <SocialLogin afterLogin={afterLogin} />
        </div>

        <div className="or-separator" style={{
          margin: "10px 0 10px"
        }}>or via email</div>

        {/* Login Content */}
        {!isNotConfrmed ? (
          <div className="login-content">
            {/* Create Account */}

            {/* Login Form */}
            {/* The Form */}
            <div className="login-form">
              <form onSubmit={loginSubmit}>
                {/* Email */}
                {/* <div className="login-form-row"> */}
                <div className='form'>
                  <Input
                    icon={<MdMail color="#808080" size={18} />}
                    type="text"
                    // inputProps={register('email', {
                    //   required: true,
                    //   pattern: EMAIL_REGEX,
                    //   value: location.state ? location.state.email : '',
                    //   onChange: (e) => {
                    //     userEmail.current = e.target.value.toLowerCase();
                    //   },
                    // })}
                    value={userData.email}
                    onChange={(value) => {
                      setUserData({
                        ...userData,
                        email: value.toLowerCase(),
                      })
                    }}
                    placeholder="Email"
                    inputStyle="form-in"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        loginSubmit();
                      }
                    }}
                  // error={errors.email}
                  />

                  {/* </div> */}

                  {/* Password */}
                  <Input
                    icon={<HiLockClosed color="#808080" size={18} />}
                    type="password"
                    // inputProps={register('password', {
                    //   required: true,
                    //   minLength: 8,
                    //   pattern: PASSWORD_REGEX,
                    // })}
                    value={userData.password}
                    onChange={(value) => {
                      setUserData({
                        ...userData,
                        password: value,
                      })
                    }}
                    variant='dark'
                    placeholder="Password"
                    inputStyle="form-in"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        loginSubmit();
                      }
                    }}
                  // error={errors.password}
                  />

                </div>

                {/* Forgot Password */}
                <div className="login-forgot-pass font-light mt-3">
                  Forgot your password?{" "}
                  <a onClick={onForgotPassword} href="#">
                    Reset
                  </a>
                </div>

                <div className="login-error">
                  {loginError ? (
                    <>
                      {loginErrorCode &&
                        Object.keys(LoginErrorMessages).includes(loginErrorCode)
                        ? LoginErrorMessages[loginErrorCode].text
                        : loginErrorMessage}

                      {errors.email ? (
                        <div className="form-error-text">
                          Please enter a valid email
                        </div>
                      ) : null}

                      {errors.password ? (
                        <div className="form-error-text">
                          password should include at least 8 characters, 1
                          uppercase, 1 lowercase, 1 number
                        </div>
                      ) : null}

                      {loginErrorCode === 'UserNotConfirmedException' && (
                        <Button
                          onClick={onSendAgain}
                          variant="link"
                          className="resend"
                        >
                          send a verification link to my email
                        </Button>
                      )}
                    </>
                  ) : null}
                </div>
                {status && <div className="status">{status}</div>}

                {/* Form Submit */}
                <div className="login-form-submit">
                  <Button onClick={loginSubmit} variant='green' disabled={isLoading} isActive={isLoading} >Login</Button>
                  {/* <CircleButton isActive={isLoading} type="submit" /> */}
                </div>

                <div className="login-or-create font-light">
                  New here? <a onClick={onRegister}>Create account</a>
                </div>
              </form>
            </div>

            {/* Login Error */}
          </div>
        ) : (
          <ConfirmCode
            email={userEmail.current}
            onSendAgain={onSendAgain}
            onBack={onBack}
            onCode={onCode}
            errorMessage={codeStatus}
          />
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Login;
