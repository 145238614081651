import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import "./affiliate-screen.scss";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Input from "../components/input/Input";
import Button from "../components/button/Button";
import axios from "axios";
import { AffiliateData, getAffiliateList, getUser } from "api/api";
import IntroLogo from "../assets/images/intro-logo.png";
import { AiOutlineCopy } from "react-icons/ai";
import { BsInfoSquareFill } from "react-icons/bs";
import { RiAwardFill, RiInformationFill } from "react-icons/ri";
import { getAppData, getNavigationData } from "redux/selectors";
import { useAppSelector } from "redux/store";
import { useNavigate } from "react-router-dom";
import { getStoreData, getStoreDataSync, setStoreData } from "platform-utils/utils";
import { StoreKey } from "redux/store-keys";


export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const newAffiliateData = () => {

  return {
    first_name: "",
    last_name: "",

    email: "",
    paypal_email: undefined,
    discount: 50,
    website: undefined,

    instagram: undefined,
    facebook: undefined,

    youtube: undefined,
    tiktok: undefined,
    twitter: undefined,
    blog: undefined,

    description: undefined,
    plan_drop_id: 100,
  }
}

// 15W6pP5dxg4slVNuv2kUk2znOowwGUKX5NbY1vqi
const AffiliateScreen = () => {
  const key = localStorage.getItem('key');
  const { isLoggedIn, deviceId } = useAppSelector(getAppData);
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false);

  let machineId = getStoreDataSync(StoreKey.DeviceId);
  if (!machineId) {
    machineId = Math.random().toString(36) + Math.random().toString(36) + Math.random().toString(36),
      setStoreData(StoreKey.DeviceId, machineId);
  }

  const onSave = (data: IAffiliateData) => {
    axios.post("https://api.getsound.ai/partners/create-affiliate", {
      ...data,
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
        "machine_id": machineId,
      }
    })
      .then(() => {
        getData();
        setShowForm(false); // close form
      })

  }

  const [isForbidden, setIsForbidden] = useState(false);
  const [showAffiliateForm, setShowAffiliateForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    setIsLoading(true);

    getUser().then((response) => {
      if (response) {
        console.log(response, "response");
        if (response.user.affiliate) {
          getAffiliateList().then((response) => {
            if (response.affiliate) {
              setAffiliateList(response.affiliate);

              setTotalStats({
                signups: response.affiliate.reduce((a, b) => a + parseInt(b.signups), 0),
                sales: response.affiliate.reduce((a, b) => a + b.sales.reduce((a, b) => a + b.profit, 0), 0),
                pending: response.affiliate.reduce((a, b) => a + parseInt(b.total_revenue), 0),
                // profit: response.total_profit,
              });
            }
            setIsLoading(false);

          })
        } else {
          setShowAffiliateForm(true);
        }
      } else {
        setIsLoading(false);
      }

    });


  }, []);


  useEffect(() => {
    getData()
  }, []);

  const [affiliateList, setAffiliateList] = useState<AffiliateData[]>([]);
  const [affiliateData, setAffiliateData] = useState<AffiliateData | undefined>();

  const [totalStats, setTotalStats] = useState({
    signups: 0,
    sales: 0,
    pending: 0,
  });

  return <div className="screen affiliate-screen">
    <div className="sideblock horizontal">
      <div className="header">
        <a href="#" className="logo-container" onClick={() => {
          window.location.href = "/";
        }}>
          {/* <img src={IntroLogo} alt="" className='intro-logo' /> */}
        </a>

      </div>

      <h1 className="font-light text-3xl">Affiliate Dashboard</h1>


      <button className="logout" onClick={() => {
        localStorage.clear();
        window.location.reload();
      }}>Logout</button>
    </div>

    {isLoading && <div className="loading">
      <div className="spinner"></div>
    </div>}


    <div className="content">


      {/* 
      {isForbidden && <div className="forbidden">
        <h1>Forbidden</h1>
        <p style={{
          color: "#fff",
        }}>You don't have access to this page.</p>
      </div>} */}

      {showAffiliateForm && !isLoading && <AffiliateForm onSave={onSave} onClose={() => {
        setShowAffiliateForm(false);
      }} />}
      {!showAffiliateForm && !isLoading && <div className="affiliate-list">

        <div className="summary">
          <div className="totals"><span>Your Totals</span></div>

          <div className="summary-list">
            <SummaryValue name="Campaigns" icon={<RiAwardFill color="#fff" />} value={nFormatter(affiliateList.length, 3)} />
            <SummaryValue name="Signups" icon={<RiAwardFill color="#fff" />} value={nFormatter(totalStats.signups, 0)} />
            <SummaryValue name="Sales types" icon={<RiAwardFill color="#fff" />} value={affiliateList.reduce((a, b) => a + b.sales.length, 0)
            } />
            <SummaryValue name="Total revenue" icon={<RiAwardFill color="#fff" />} value={`${totalStats.sales}$`} />
          </div>

        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Discount</th>
                <th>Signups</th>
                <th>Sales types</th>
                <th>Url</th>
              </tr>
            </thead>
            <tbody>
              {affiliateList.map((affiliate) => {
                return <tr key={affiliate.url}>
                  <td>
                    {affiliate.description}
                  </td>
                  <td>
                    {affiliate.discount}%
                  </td>

                  <td>
                    {addThousandSeparator(affiliate.signups)}
                  </td>
                  <td style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    {/* <div>
                      {addThousandSeparator(affiliate.sales.length)}
                    </div> */}


                    <div className="profit-data">
                      <div>PM: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'personal' && _.plan_interval === 'month').length}</span></div>
                      <div>PY: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'personal' && _.plan_interval === 'year').length}</span></div>
                      <div>SBM: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'business' && _.plan_interval === 'month').length}</span></div>
                      <div>SBY: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'business' && _.plan_interval === 'year').length}</span></div>
                    </div>


                    {/* <BsInfoSquareFill color="#16CFA3" size={20} />


                    <div className="table-tooltip">
                      
                    </div> */}

                  </td>
                  <td>
                    <div className="flex flex-row justify-between align-items-center">
                      <a style={{
                        color: "#fff",
                      }} href={`https://app.getsound.ai/${affiliate.url}`} target="_blank" rel="noreferrer">{affiliate.url}</a>

                      <button style={{
                        color: "#fff",
                      }} className="copy-button" onClick={() => {
                        copyTextToClipboard(`https://app.getsound.ai/${affiliate.url}`);
                      }}><AiOutlineCopy color="#fff" size={20} /></button>
                    </div>

                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div>
  </div>
}


interface IAffiliateData {
  id?: string;
  url?: string; //
  first_name: string; //
  last_name: string; //
  email: string; //
  paypal_email: string; //
  discount: number;

  instagram: string;//
  facebook: string; //

  youtube: string; //
  tiktok: string; //
  twitter: string;

  website: string; //
  blog: string; //

  description: string; //
  plan_drop_id: number;
  signups?: string;
  sales?: {
    [key: string]: number;
  };
  signed_date?: string;
  created_at?: string;
  updated_at?: string;
  active?: boolean;
}

interface AffiliateFormProps {
  data?: any;
  onSave: (data: any) => void;
  onClose: () => void;
}


const REQUIRED = [
  'first_name',
  'last_name',
  'email',
  'discount',
  'plan_drop_id',
];

const AffiliateForm: FC<AffiliateFormProps> = ({ data, onSave, onClose }) => {

  const [affiliateData, setAffiliateData] = useState<IAffiliateData>({} as IAffiliateData);

  const onChange = (field: keyof IAffiliateData) => (value: any) => {
    setAffiliateData(prev => ({ ...prev, [field]: value }))

    if (["email"].indexOf(field) !== -1) {
      if (!EMAIL_REGEX.test(value)) {
        setError("Invalid email");
        return;
      } else {
        setError("");
      }
    }
    if (["paypal_email"].indexOf(field) !== -1) {
      if (!EMAIL_REGEX.test(value)) {
        setError("Invalid Paypal email");
        return;
      } else {
        setError("");
      }
    }
  }
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (data) {
      setIsNew(false);
      setAffiliateData(data);
    } else {
      setIsNew(true);
      setAffiliateData(newAffiliateData());
    }
  }, [data]);

  const [error, setError] = useState("");

  const onSaveClick = () => {

    const missing = REQUIRED.filter((field) => {
      return !affiliateData[field as keyof IAffiliateData];
    });

    if (missing.length) {
      setError(`Missing fields: ${missing.join(", ")}`);
      return;
    }

    onSave(affiliateData);
  }

  return <div className="affiliate-form">

    {/* <button className="close-button" onClick={onClose}>
      <IoIosCloseCircleOutline color="#fff" size={24} />
    </button> */}

    <h1>Become an affiliate</h1>

    <div className="input-row">
      <Input value={affiliateData?.first_name ?? ""} placeholder="First name" onChange={onChange("first_name")} type={"text"} />
      <Input value={affiliateData?.last_name ?? ""} placeholder="Last name" onChange={onChange("last_name")} type={"text"} />
    </div>

    <div className="input-row">
      <Input value={affiliateData?.email ?? ""} placeholder="Email" onChange={onChange("email")} type={"text"} />
    </div>

    <div className="input-row">
      <Input value={affiliateData?.instagram ?? ""} placeholder="Instagram" onChange={onChange("instagram")} type={"text"} />
      <Input value={affiliateData?.facebook ?? ""} placeholder="Facebook" onChange={onChange("facebook")} type={"text"} />
      <Input value={affiliateData?.youtube ?? ""} placeholder="Youtube" onChange={onChange("youtube")} type={"text"} />
    </div>

    <div className="input-row">
      <Input multiline value={affiliateData?.description ?? ""} placeholder="Description" onChange={onChange("description")} type={"text"} />
    </div>

    <div className="error">{error}</div>

    <div className="mt-1">
      <Button type="submit" variant="green" onClick={onSaveClick}>Submit</Button>
    </div>

  </div>
}

export default AffiliateScreen;

const SummaryValue = (props: SummaryValueProps) => {

  const { name, value, icon } = props;

  return <div className="summary-value">
    {icon}
    <span className="name">{name}</span>
    <span className="value">{value}</span>
  </div>
}

interface SummaryValueProps {
  name: string;
  value: string | number;
  icon: ReactNode;
}

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    console.log('Async: Copying to clipboard was successful!');
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

function addThousandSeparator(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}