export const PLATFORM = 'web';

export const GOOGLE_MAPS_KEY = 'AIzaSyBzbXktFYMpI2jzTDPH56C6h1fjChdU2H8';

export const MIXPANEL_TOKEN = 'c9da2a872d0a5a1df0016b2fb4e565eb';


export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/;
export const NAME_REGEX = /^([\w]{3,})+\s+([\w\s]{3,})+$/i;

export const ENV_FREE_LIMIT = 3;
export const GLOBE_FREE_LIMIT = 2;

export const TEMP_UNITS = ['c', 'f'];

export const PLAYBACK_RATE = 1;
export const PLAYER_ENV_SOUND_MULTIPLIER = 0.85;
export const PLAYER_WEATHER_SOUND_MULTIPLIER = 0.85;

export const VOLUME_STEP = 0.05;

export const PRICING_TABLE = {
  month_1: 199,
  month_2: 149,
  year_1: 1788 / 12,
  year_2: 1299 / 12,
};

export const PRIVACY_LINK = 'https://www.getsound.ai/privacy.html';
export const TERMS_LINK = 'https://www.getsound.ai/terms.html';

export const STRIPE_PUBLIC_KEY = 'pk_live_51LabFHEwBaLCdq6q1YGsDaNFshf2p7hfj8eJ7iGdQC89DVztTgeBq41sl0V7CAj3XA7iijB7tW8EgfnnghTBw0dt00vr0JUJO7';
export const STRIPE_CUSTOMER_PORTAL =
  'https://billing.stripe.com/p/login/14keVDeHP4zLgV24gg';

export const API_BASE_URL = 'https://api.getsound.ai';

export const TRAVEL_MODE_TIMEOUT = 5 * 60 * 60 * 1000; // 5 hours
export const TRAVEL_MODE_LOCATION_REFRESH = 15 * 60 * 1000; // 15 minutes

export const RUNOUT_PERIODIC_UPDATE_TIME = 15 * 60 * 1000; // 15 minutes
export const PAYMENT_PLANS_PERIODIC_UPDATE_TIME = 15 * 60 * 1000; // 15 minutes

export const NOTIFICATION_PERIODIC_UPDATE_TIME = 60 * 60 * 1000; // 60 minutes
export const NOTIFICATION_HIDE_TIME = 5 * 60 * 1000; // 5 minutes

export const ASSETS_PATH = (window && window.electron) ? 'getsound-media-loader:///assets/' : 'https://dn8m18cw2gf8t.cloudfront.net/';
