import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';

export default createAsyncThunk<unknown, void, { state: RootState }>(
  'app/notifyTray',
  async (_, { getState }) => {
    if (window && window.electron) {
      const { player, user, auth, app } = getState();
      const trayData = {
        isLoggedIn: auth.isLoggedIn,
        interfaceLocked: app.interfaceLocked,
        idToken: auth.userTokenData?.idToken,
        isPlaying: player.isPlaying,
        isRefreshAbuse: user.restrictions.refresh_abuse,
        currentPlan: user.plan_info.current_plan,
        userEnv: player.userEnvState,
        isRefreshingSound: player.isRefreshingSound,
        isCrossfading: player.isCrossfading,
        volume: player.volume,
        userWeatherSoundState: player.userWeatherSoundState,
        weather: app.interfaceData.weather,
        userCity: user.general.city,
        version: app.version,
      };

      window.electron.app.updateTrayState(trayData);
    }
  }
);
