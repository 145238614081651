import { FC, useCallback, useEffect, useState } from 'react';
import Button from './common/abstract-button/Button';

interface AppUpdateProps {}

const AppUpdate: FC<AppUpdateProps> = (props) => {
  const {} = props;

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(0);
  const [updateDownloaded, setUpdateDownloaded] = useState(false);

  useEffect(() => {
    if (window && window.electron) {
      window.electron.app.onUpdateAvailable(() => {
        console.log('update available');
        setUpdateAvailable(true);
      });

      window.electron.app.onUpdateDownloaded(() => {
        setUpdateDownloaded(true);
      });

      window.electron.app.onUpdateProgress((_, value) => {
        setUpdateAvailable(true);
        setUpdateProgress(value);
        if (updateProgress >= 100) {
          setUpdateDownloaded(true);
        }
        console.log(value);
      });

      return () => {
        window.electron.app.removeUpdateListeners();
      };
    }
  }, []);

  const onUpdateClick = useCallback(() => {
    console.log(123);
    if (window && window.electron) {
      window.electron.app.restartToUpdate();
    }
  }, []);

  if (!updateAvailable) return null;

  return (
    <div className="app-update">
      <span>Downloading update: {parseFloat(updateProgress).toFixed(2)}%</span>
      {updateDownloaded && (
        <div className="progressbar" style={{ width: `${updateProgress}%` }} />
      )}

      {updateDownloaded && (
        <div className="update">
          <span>
            A new update has been downloaded.
            <br /> Please wait while the app is being restarted
          </span>

          <Button onClick={onUpdateClick} variant="green">
            Restart the app
          </Button>
        </div>
      )}
    </div>
  );
};

export default AppUpdate;
