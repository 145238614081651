export interface UserResponse {
  success: boolean;
  user: User;
}

interface User {
  address: string;
  business_name: string;
  business_website: string;
  city: string;
  country_code: string;
  created_at: string;
  current_plan_name: PaymentPlan;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  play_on_startup: boolean;
  profile_picture: string;
  receive_emails: boolean;
  receive_push: boolean;
  temp_unit: 'c' | 'f';
  uid: string;
  vat: string;
  zones_allowed: number;
}

export enum EnvPaidType {
  Free = 'free',
  Paid = 'paid',
}

export interface IEnvState {
  state: string;
  env: string;
  text: string;
  image: string;
  sound: string;
  order?: number;
  type: EnvPaidType;
}

export interface CouponResponse {
  success: boolean;
  coupon_info?: CouponInfo;
  message?: string;
  code?: string;
}

export interface PaymentPlanResponse {
  success: boolean;
  payload: PaymentPlanInfo[];
}

export interface PaymentResponse {
  success: boolean;

  payment_info: any;
}

export interface SingUpResponse {
  success: boolean;
  user: {
    auth: any;
    sub: string;
  };
  code?: string;
  message?: string;
}

export interface UpdateMachineInfoResponse {
  success: boolean;
}

export interface UpdateMachineInfoRequest {
  machine_id: string;
  machine_new_name: string;
}

export enum PaymentPeriod {
  Monthly = 'month',
  Yearly = 'year',
}

export interface PaymentRequest {
  period: PaymentPeriod;
  zones: number;
  country: string | null;
  countryCode: string | null;
  business_name: string;
  cardholder_name: string;
  vat: string;
  city: string;
  addressLine1: string;
  state: string;
  zip: string;
  coupon: null | string | CouponInfo;
  terms: boolean;
  plan: PaymentPlan;
}

export interface CouponInfo {
  id: string;
  amount_off: any;
  created: number;
  currency: string;
  duration: 'forever';
  duration_in_months: number;
  livemode: boolean;
  max_redemptions: number;
  metadata: any;
  name: string;
  object: string;
  percent_off: number;
  redeem_by: null;
  times_redeemed: number;
  valid: boolean;
}

export interface WeatherData {
  air_quality: {
    co: number;
    condition: string;
    'gb-defra-index': number;
    no2: number;
    o3: number;
    pm2_5: number;
    pm10: number;
    so2: number;
    'us-epa-index': number;
  };
  cloud: number;
  condition: { code: number; icon: string; text: string };
  current_time: string;
  feelslike_c: number;
  feelslike_f: number;
  gust_kph: number;
  gust_mph: number;
  humidity: number;
  is_day: number;
  last_updated: string;
  last_updated_epoch: number;
  precip_in: number;
  precip_mm: number;
  pressure_in: number;
  pressure_mb: number;
  lat: number;
  lng: number;
  temp_c: number;
  temp_f: number;
  uv: number;
  vis_km: number;
  vis_miles: number;
  wind_degree: number;
  wind_dir: string;
  wind_kph: number;
  wind_mph: number;
}

export interface IInterfaceData {
  weather: WeatherData | null;
  sideMenuDeal: SideMenuDeal | null;
}

export interface ModalData {
  type: ModalType | null;
  data?: any;
  onReturn?: ModalType | undefined;
}

export interface AddZonesData {
  period: PaymentPeriod;
  actualZones: number;
  newZones: number;
  terms: boolean;
  coupon: null | CouponInfo;
}

export enum ModalType {
  Runout = 'runout_modal',
  Quiz = 'quiz_modal',
  Terminate = 'terminate_modal',
  ConnectionProblem = 'connection_problem_modal',
  ServerUpgrading = 'server_upgrading_modal',
  UserRefresh = 'user_refresh_modal',
  NoLocation = 'no_location_modal',
  ZeroVolume = 'zero_volume_modal',
  Payment = 'payment_modal',
  AddZones = 'add_zones_modal',
  ContactUs = 'contact_us_modal',
}

export enum HomeViewType {
  Home = 'home',
  Environment = 'env',
  Globe = 'globe',
  Payment = 'payment',
}

export enum PaymentPlan {
  Free = 'free',
  Personal = 'personal',
  Business = 'business',
  Unlimited = 'unlimited',
  Enterprise = 'enterprise',
}

export interface PaymentPlanInfo {
  id: PaymentPlan;
  name: string;
  advantages: PaymentPlanAdvantage[];

  buttonText: string;
  buttonStyle: 'dark' | 'green';

  contactText?: string;
  contactStyle?: 'grey';

  price_monthly: number;
  price_yearly_per_month: number;
  additional_zone_price: number;
}

export interface PaymentPlanAdvantage {
  label: string;
  active: boolean;
}

export interface AudioData {
  env: string; // "grassland"
  main: string; // url
  watermark: boolean;
  weather: string; // "sun"
}

export enum PlayerType {
  Main = 'main',
  Environment = 'env',
  Weather = 'weather',
}

export interface UserTokenData {
  deviceId: string;
  idToken: string;
  refreshToken: string;
}

interface UserCoupon {
  name: string;
  percent_off: number;
  redeem_by_hours: number;
}

export interface UserInfo {
  uid: string;
  first_name: string;
  last_name: string;
  created_at: string;
  profile_picture: string;
  email: string;
  city: string;
  ip: string;
  country: string;
  time_of_day: string;
  current_hour: string;
  current_min: string;
  stripe_customer_id: string;
  signup_ref_id: boolean;
}

export interface UserPreferences {
  temp_unit: 'c' | 'f';
  user_env: string;
  receive_emails: boolean;
  receive_push: boolean;
  play_on_startup: boolean;
  load_on_startup: boolean;
  last_main_vol: number;
  last_env_vol: number;
  last_weather_vol: number;
  weather_sync: boolean;
}

export interface UserPlan {
  current_plan: PaymentPlan;
  zones_allowed: number;
  subscription_interval: PaymentPeriod;
  zone_abuse: boolean;
  coupon?: UserCoupon;
}

export interface UserRestrictions {
  should_get_watermark: boolean;
  refresh_abuse: boolean;
  daily_usage: number;
  daily_usage_limit: number;
  usage_limit_reached: boolean;
  env_locks: boolean;
  globe_locks: boolean;
}

export interface GlobalSettings {
  max_env_volume: number;
  max_weather_volume: number;
  max_main_volume: number;
  watermark_volume: number;
  update_check_interval_min: number;
}

export interface UserData {
  current_machines: MachineInfo[];
  general: UserInfo;
  preferences: UserPreferences;
  plan_info: UserPlan;
  restrictions: UserRestrictions;
  global_settings: GlobalSettings;
  show_quiz: boolean;
}

export interface SideMenuDeal {
  image_url: string;
  target_url: string;
  upper_text: string;
  center_text: string;
  bottom_text: string;
}

export interface ReportData {
  track_name: string;
}

export interface QuizResponse {
  success: boolean;
  quiz_data: any;
}

export interface NotificationResponse {
  success: boolean;
  notification: INotification;
}

export interface INotificationButton {
  [key: string]: string;
}

export interface INotification {
  type: NotificationType;
  title: string;
  description: string;

  icon_url?: string;
  button: INotificationButton | null;
}

export interface GetSoundResponse {
  weather: WeatherData;
  bg: Background;
  soundscapes: AudioData;
  user_data: UserData;
  success: boolean;
  side_menu_deal: SideMenuDeal;
}

interface Background {
  image: string;
  background: string;
}

export interface MachineInfo {
  created_at: string;
  ip: string;
  location: string;
  machine_id: string;
  machine_name: string;
  uid: string;
  updated_at: string;
  user_agent: string;
}

export interface TerminateSessionData {
  new_machine_id: string;
  machine_id_to_deleted: string;
}

export interface TerminateSessionResponse {
  success: boolean;
}

export enum UsageStatus {
  Play = 'play',
  Pause = 'pause',
  Refresh = 'refresh',
}

type NotificationType = 'small' | 'medium' | 'large';

export interface AppStore {
  app: AppStateSlice;
  auth: AuthStateSlice;
  user: UserSlice;
  player: PlayerStateSlice;
  stats: StatsStateSlice;
}

export interface TutorialEffects {
  tutorialHidden?: boolean;
  menuHidden?: boolean;
  weatherHidden?: boolean;
  controlsHidden?: boolean;
  volumeHidden?: boolean;
  globeTip?: boolean;
  envTip?: boolean;
  finalTip?: boolean;
}

export interface AppStateSlice {
  appUpdating: boolean;
  isTutorialPassed: boolean;
  isFirstTime: boolean;
  tutorialEffects: TutorialEffects | null;
  isGlobe: boolean;
  interfaceLocked: null | ModalType;
  recentlyUpdatedPlan: boolean;
  paymentPlans: PaymentPlanInfo[];

  modal: ModalData;
  fadeModal: boolean;
  homeView: HomeViewType;
  interfaceData: IInterfaceData;
  showSplash: boolean;
  menuOpen: boolean;
  isInitialLoading: boolean;
  hideSplashStarted: boolean;
  background: string | null;
  isWeatherOutdated: boolean;
  googleCoordinates: { lat: number; lng: number };
  userCoordinates: { lat: number; lng: number };
  notifications: INotification | null;

  version: string | null;
  deviceId: string | null;
  deviceName: string | null;
  suspendGetSoundData: boolean;
  showZeroSoundNotification: boolean;
  showDebug: boolean;
  showHomeVolume: boolean;
  travelMode: boolean;
  locationPickerEnabled: boolean;
}

export interface StatsStateSlice {
  playerStats: {
    currentTrack: string | null;
    p1: {
      current: number;
      buffered: number;
      duration: number;
      volume: number;
    };
    p2: {
      current: number;
      buffered: number;
      duration: number;
      volume: number;
    };
  };
}

export interface PlayersVolumes {
  main: number;
  env: number;
  weather: number;
  source: VolumeUpdateSource | null;
}

export interface PlayerStateSlice {
  mainVolume: number;
  activePlayer: number | null;
  isPlaying: boolean;
  isMainPlaying: boolean;
  isEnvSelectPlaying: boolean;
  isForcedRefresh: boolean;
  isRefreshingSound: boolean;
  isCrossfading: boolean;

  userEnvState: string | null;
  userEnvVolume: number;
  userWeatherState: string;
  userWeatherSoundState: boolean;

  watermark: boolean | string;

  playerEnvRealVolume: number;
  playerWeatherRealVolume: number;

  volume: PlayersVolumes;

  nextTrack: AudioData;
  playlist: AudioData[];
}

export enum VolumeUpdateSource {
  HomeCircle = 'home-circle',
  Tray = 'tray',
  Menu = 'menu',
  Keyboard = 'keyboard',
  Server = 'server',
  ZeroSoundNotification = 'zero-sound-notification',
  EnvironmentScreen = 'environments-screen',
}

export interface AuthStateSlice {
  authLoading: boolean;
  isLoggedIn: boolean;
  userTokenData: UserTokenData | null;
}

export interface UserSlice {
  current_machines: MachineInfo[];
  general: UserInfo;
  preferences: UserPreferences;
  plan_info: UserPlan;
  restrictions: UserRestrictions;
  global_settings: GlobalSettings;
  show_quiz: boolean;

  coupon?: UserCoupon;
}

export interface ChangePasswordData {
  old_password: string;
  new_password: string;
  access_token: string;
}
