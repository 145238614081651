import axios from 'axios';
import { Auth } from 'aws-amplify';
import { StoreKey } from '../redux/store-keys';
import { API_BASE_URL } from '../constants';
import {
  ChangePasswordData,
  CouponResponse,
  GetSoundResponse,
  NotificationResponse,
  PaymentPlanResponse,
  PaymentRequest,
  PaymentResponse,
  QuizResponse,
  ReportData,
  TerminateSessionData,
  TerminateSessionResponse,
  UpdateMachineInfoRequest,
  UpdateMachineInfoResponse,
  UsageStatus,
  UserResponse,
} from '../redux/types';
import {
  getStoreData,
  setStoreData,
} from '../platform-utils/utils';

export const apiClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    let appData = await getStoreData(StoreKey.AppData);

    const tokenData = await Auth.currentSession();

    if (!appData) {
      appData = {};
    }

    if (!appData.userTokenData) {
      appData.userTokenData = {};
    }

    appData.userTokenData.idToken = tokenData.getIdToken().getJwtToken();
    appData.userTokenData.accessToken = tokenData
      .getAccessToken()
      .getJwtToken();

    let deviceId = await getStoreData(StoreKey.DeviceId);
    if (!deviceId) {
      deviceId = Math.random().toString(36) + Math.random().toString(36) + Math.random().toString(36);
      setStoreData(StoreKey.DeviceId, deviceId);
    }

    config.headers.Authorization = tokenData.getIdToken().getJwtToken();
    config.headers.machine_id = deviceId


    // debugger;
    setStoreData(StoreKey.AppData, appData);

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest.isRetry
    ) {
      originalRequest.isRetry = true;
      const token = await Auth.currentSession();
      apiClient.defaults.headers.common.Authorization = `Bearer ${token
        .getIdToken()
        .getJwtToken()}`;

      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

interface AddZoneResponse {
  success: boolean;
}

export const partnersPaypalPaymentCapture = async (data: any) => {
  try {
    const response = await apiClient.post("/partners/paypal-payment-create", data);
    return response.data
  } catch (e) {
    console.log(e);
    return { success: false, message: e.message };
  }
}

export const partnersPaypalOrderCreate = async (data: any) => {
  try {
    const response = await apiClient.post("/partners/paypal-order-create", data);
    return response.data
  } catch (e) {
    console.log(e);
    return { success: false, message: e.message };
  }
}

export const partnersPayment = async (data: any) => {
  try {
    const response = await apiClient.post("/partners/payment-create", data);
    return response.data
  } catch (e) {
    console.log(e);
    return { success: false, message: e.message };
  }
}

export const getTrack = async (
  url: string
): Promise<AddZoneResponse | null> => {
  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    return response.data;
  } catch (e) {
    return null;
  }
};

export const addZone = async (data: any): Promise<AddZoneResponse | null> => {
  try {
    const response = await apiClient.post('/user/payment/zone/add', data);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const updateMachineInfo = async (
  data: UpdateMachineInfoRequest
): Promise<UpdateMachineInfoResponse | null> => {
  try {
    const response = await apiClient.patch('/user/machine', data);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getPaymentPlans =
  async (): Promise<PaymentPlanResponse | null> => {
    try {
      const response = await apiClient.get('/pricing');
      return response.data;
    } catch (e) {
      return null;
    }
  };

export const userPayment = async (
  data: PaymentRequest
): Promise<PaymentResponse | null> => {
  try {
    const response = await apiClient.post('/user/payment', data);
    return response.data;
  } catch (e) {
    return null;
  }
};

export interface AffiliateData {
  description: string;
  discount: number;
  paid_payment_usd: number;
  paypal_email: string;
  pending_payment_usd: number;
  pay_factor: number;
  sales: [{ [key: string]: number; }];
  signups: number;
  url: string;
}

interface AffiliateListResponse {
  success: boolean;
  affiliate: AffiliateData[];
}

export const getAffiliateList = async (): Promise<AffiliateListResponse | null> => {
  try {
    const response = await apiClient.get('/affiliate');

    return response.data;

  } catch (e) {
    return null;
  }
}

export const applyCoupon = async (
  coupon: string
): Promise<CouponResponse | null> => {
  try {
    const response = await apiClient.post('/user/payment/coupon', { coupon });
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getUser = async (): Promise<UserResponse | null> => {
  const response = await apiClient.get('/user');
  return response.data;
};

export const uploadUserImage = async (filename: string) => {
  const response = await apiClient.post('/user/image', {
    key: filename,
  });
  return response.data;
};

interface ErrorWithPayload {
  type: string;
  payload: any;
}

export const errorLog = async (
  errorMsg: string | ErrorWithPayload,
  payload?: any
): Promise<boolean> => {
  try {
    console.log(typeof payload);
    await apiClient.post('/alert', {
      event_type: 'error',
      error_msg: errorMsg,
      payload: JSON.stringify(payload),
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
  // return response.data;
};

export const getQuiz = async (): Promise<QuizResponse | null> => {
  const response = await apiClient.get('/quiz');
  return response.data;
};
export const sendQuizResults = async (
  data: any
): Promise<QuizResponse | null> => {
  const response = await apiClient.post('/quiz', data);
  return response.data;
};

export const getNotifications =
  async (): Promise<NotificationResponse | null> => {
    const response = await apiClient.get('/user/notifications');
    return response.data;
  };

export const getSound = async (data: any): Promise<GetSoundResponse> => {
  const response = await apiClient.post('/sound/get', data);
  return response.data;
};


export const updateUserRefId = async (refId) => {
  try {
    const response = await apiClient.post(`/user/cold-update`, { referral: refId });
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const updateUser = async (data: any): Promise<any | null> => {
  const response = await apiClient.post(`/user/update`, data);
  return response.data;
};

export const updateUsage = async (status: UsageStatus): Promise<any | null> => {
  try {
    const response = await apiClient.post(`/user/usage/set/${status}`);
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const sendReportApi = async (data: ReportData): Promise<any | null> => {
  try {
    const response = await apiClient.post(`/admin/report`, data);
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const terminateSession = async (
  data: TerminateSessionData
): Promise<TerminateSessionResponse | null> => {
  try {
    const response = await apiClient.put(`/user/zone`, data);
    return response.data;
  } catch (e: any) {
    // debugger;
    // dispatch(showModal({ type: ModalType.ServerUpgrading }));
    console.log(e);
    return e.message;
  }
};

export const isUpdateAllowed = async () => {
  try {
    const response = await apiClient.get(`/user/auto-upgrade`);
    return response.data;
  } catch (e: any) {
    console.log(e);
    return e.message;
  }
};

interface ContactUsRequest {
  full_name: string;
  phone: string;
}

interface ContactUsResponse {
  success: boolean;
}

export const contactUsRequest = async (
  data: ContactUsRequest
): Promise<ContactUsResponse | null> => {
  try {
    const response = await apiClient.post(`/user/contact`, data);
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const changePassword = async (
  data: ChangePasswordData
): Promise<any | null> => {
  try {
    const response = await apiClient.post('/user/change-password', data);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const deleteAccount = async (): Promise<any | null> => {
  try {
    const response = await apiClient.post('/user/delete');
    return response.data;
  } catch (e) {
    return null;
  }
};

export const updatePushToken = async (
  push_token: string
): Promise<any | null> => {
  try {
    const response = await apiClient.post('/user/update-push-token', {
      push_token,
    });
    return response.data;
  } catch (e) {
    return null;
  }
};
