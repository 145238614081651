import { googleGetLocation, maxmindGetLocation } from '../api/third-party-api';
import { StoreKey } from '../redux/store-keys';
import { AnalyticsEvent } from '../redux/service/analytics-events';
import { ENV_VOLUME_DOWN, ENV_VOLUME_MUTE, ENV_VOLUME_UP, MAIN_VOLUME_DOWN, MAIN_VOLUME_MUTE, MAIN_VOLUME_UP, PLAY_PAUSE_KEY, REFRESH_SOUND, WEATHER_VOLUME_DOWN, WEATHER_VOLUME_MUTE, WEATHER_VOLUME_UP } from 'constants/key-shortcuts';
import changeVolumeAction from 'redux/actions/changeVolumeAction';
import { PlayerType, VolumeUpdateSource } from 'redux/types';
import { VOLUME_STEP } from 'constants/config';
import playerPlayAction from 'redux/actions/playerPlayAction';
import refreshSoundAction from 'redux/actions/refreshSoundAction';
import mixpanel from 'mixpanel-browser';

export const getStoreData = (key: StoreKey): Promise<any> => {
  return new Promise((resolve) => {
    return resolve(JSON.parse(localStorage.getItem(key)));
  });
};

export const getStoreDataSync = (key: StoreKey) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getLocation = () => {
  // return getStoreData(StoreKey.Location);
  // return new Promise((resolve) => {
  //   maxmindGetLocation()
  //     .then((result) => {
  //       resolve(result);
  //       return true;
  //     })
  //     .catch((e) => {
  //       console.log('no location');
  //     });
  // });
};

export const removeStoreData = async (key: StoreKey) => {
  return localStorage.removeItem(key);
};

export const setStoreData = async (key: StoreKey, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const setAnalytcisUser = (data) => {
  console.log(data);
  const userId = getStoreDataSync(StoreKey.DeviceId);

  const userData = {
    platform: 'web',
    $email: data.email,
  };

  console.log(userData);

  mixpanel.people.set(
    userData
  );
};

export const sendAnalyticsEvent = (event: AnalyticsEvent, data?: any) => {
  console.log(event, data);
  mixpanel.track(event, data);
};

export const isBetween = (value: number, min: number, max: number) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};


export const useKeyListener = (dispatch) => {

  window.onkeyup = (e) => {

    if (window.preventKeyBindings) return;

    switch (e.key) {
      case MAIN_VOLUME_UP:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Main,
            change: VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case MAIN_VOLUME_DOWN:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Main,
            change: -VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case MAIN_VOLUME_MUTE:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Main,
            action: 'mute',
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case ENV_VOLUME_UP:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Environment,
            change: VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case ENV_VOLUME_DOWN:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Environment,
            change: -VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case ENV_VOLUME_MUTE:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Environment,
            action: 'mute',
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case WEATHER_VOLUME_UP:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Weather,
            change: VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case WEATHER_VOLUME_DOWN:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Weather,
            change: -VOLUME_STEP,
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case WEATHER_VOLUME_MUTE:
        dispatch(
          changeVolumeAction({
            type: PlayerType.Weather,
            action: 'mute',
            source: VolumeUpdateSource.Keyboard,
          })
        );
        break;
      case PLAY_PAUSE_KEY:
        dispatch(playerPlayAction());
        break;
      case REFRESH_SOUND:
        dispatch(refreshSoundAction());
        break;


    }

  }

}