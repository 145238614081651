import { createSlice } from '@reduxjs/toolkit';
import { StatsStateSlice } from '../types';

const initialStatsState: StatsStateSlice = {
  playerStats: {},
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState: initialStatsState,
  reducers: {
    updatePlayerStats: (state, action) => {
      state.playerStats = action.payload;
    },
  },
});

export const { updatePlayerStats } = statsSlice.actions;

export default statsSlice.reducer;
