import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ModalData, ModalType, PaymentPlan } from '../types';
import { hideNotification, openMenu, showModal } from '../reducers/appSlice';
import { updateIsPlaying } from '../reducers/playerSlice';
import lockInterfaceAction from './lockInterfaceAction';

export const modalQueue: ModalData[] = [];

export default createAsyncThunk<unknown, ModalData, { state: RootState }>(
  'app/showModalAction',
  async (data, { dispatch, getState }) => {
    console.log('show modal', data, modalQueue);
    const { app, user } = getState();

    if (
      data.type === ModalType.Payment &&
      user.plan_info.current_plan === PaymentPlan.Business
    ) {
      data.type = ModalType.AddZones;
    }

    if (!app.modal.type) {
      dispatch(showModal(data));
      if ([ModalType.Runout, ModalType.Terminate].includes(data.type)) {
        dispatch(lockInterfaceAction(data.type));
        dispatch(updateIsPlaying(false));
      }
    } else if (modalQueue.findIndex((modal) => modal.type === data.type) < 0) {
      modalQueue.push(data);
    }

    if (window && window.electron && !app.modal.type) {
      if (![ModalType.ConnectionProblem, ModalType.Quiz].includes(data.type)) {
        window.electron.windows.showMainWindow();
      }

      if ([ModalType.Payment, ModalType.AddZones].includes(data.type)) {
        window.electron.actions.lock();
      }
    }
  }
);
