import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../../components/common/abstract-button/Button';
import './confirm-code.scss';

interface ConfirmCodeProps {
  email: string;
  onCode: (code: string) => void;
  onBack: () => void;
  onSendAgain: () => void;
  errorMessage?: string;
  isSuccess?: boolean;
  showLoading?: boolean;
  isLoading?: boolean;

}

const DIGITS = 6;
const SEND_AGAIN_TIME = 60;

export default (props: ConfirmCodeProps) => {
  const { email, onCode, onBack, isSuccess = false, isLoading = false, onSendAgain, errorMessage, showLoading = false } = props;
  const [code, setCode] = useState('');
  const activeInput = useRef(0);

  const inputRefs = useRef({});

  const onChange = (e) => {

    const newValue = e.target.value;
    console.log('code', newValue);

    setCode(newValue);
    // debugger;
    if (newValue.length === 6) {
      // setIsLoading(true);
      onCode(newValue);
    }


  };

  const onFocus = () => {
    console.log('active', activeInput.current);

    if (activeInput.current >= 0 && activeInput.current < DIGITS) {
      inputRefs.current[activeInput.current].current.focus();
    }
  };

  const focusNext = () => {
    const nextInput = activeInput.current + 1;
    if (nextInput < DIGITS) {
      setTimeout(() => {
        inputRefs.current[nextInput].current.focus();
      }, 25);
      activeInput.current = nextInput;
      return false;
    }
    return true;
  };

  const focusPrev = () => {
    const prevInput = activeInput.current - 1;
    if (prevInput >= 0) {
      activeInput.current = prevInput;
      setTimeout(() => {
        inputRefs.current[prevInput].current.focus();
      }, 25);
    }
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace': {
        const newValue = code.substring(0, code.length - 1);
        setCode(newValue);
        focusPrev();
        e.stopPropagation();
        e.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text/plain')
      .substring(0, DIGITS);

    setCode(pastedData);
    if (pastedData.length === DIGITS) {
      onCode(pastedData);
    }
  };

  // const renderInputs = () => {
  //   const inputs = [];

  //   for (let i = 0; i < DIGITS; i += 1) {
  //     if (i === 3) {
  //       inputs.push(
  //         <span key="sep" className="separator">
  //           -
  //         </span>
  //       );
  //     }
  //     const ref = createRef();
  //     inputRefs.current[i] = ref;
  //     inputs.push(
  //       <input
  //         key={i}
  //         onChange={onChange(i)}
  //         ref={ref}
  //         onPaste={handlePaste}
  //         onFocus={onFocus}
  //         onKeyDown={onKeyDown}
  //         value={code[i] ?? ''}
  //         maxLength={1}
  //       />
  //     );
  //   }

  //   return inputs;
  // };

  const sendAgainTimeRef = useRef(SEND_AGAIN_TIME);
  const [sendAgainTime, setSendAgainTime] = useState(SEND_AGAIN_TIME);
  const sendAgainCountdown = useRef<NodeJS.Timer>();

  const startSendAgainCountdown = () => {
    setSendAgainTime(SEND_AGAIN_TIME);
    sendAgainTimeRef.current = SEND_AGAIN_TIME;
    sendAgainCountdown.current = setInterval(() => {
      sendAgainTimeRef.current -= 1;

      if (sendAgainTimeRef.current > 0) {
        setSendAgainTime(sendAgainTimeRef.current);
      } else {
        clearInterval(sendAgainCountdown.current);
        setSendAgainTime(0);
      }
    }, 1000);
  };

  useEffect(() => {
    startSendAgainCountdown();

    return () => {
      clearInterval(sendAgainCountdown.current);
    };
  }, []);

  const onSendAgainAction = () => {
    if (sendAgainTimeRef.current <= 0) {
      onSendAgain();
      startSendAgainCountdown();
    }
  };

  // const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [isCodeConfirmed, setIsCodeConfirmed] = useState(false);


  // useEffect(() => {
  //   if (errorMessage) {
  //     setIsLoading(false);
  //   }
  // }, [errorMessage]);


  return (
    <div className="confirm-code-inline">
      <div className='font-light' style={{
        fontSize: "15px",
      }}>Check you email for a 6 digits code and paste it here</div>
      <div className='flex flex-row justify-between mt-2'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row justify-start items-center verification-label'>YOUR VERIFICATION CODE</div>
          {/* <div style={{
            color: "#777777"
          }}> */}
          {/* <div className='text-lxl'>Sent to <span style={{ color: "#fff" }}>{email}</span></div> */}
          {/* <div className='text-lxl'>Wrong email? <a href="#" onClick={(e) => {
              e.preventDefault();
              // setStep("login");
              return false;
            }} style={{
              textDecoration: "underline",
            }}>Try again</a></div>
          </div> */}
        </div>
        <div className='flex flex-col gap-2 justify-start'>
          <div className='code-input'>
            <input type="text" placeholder='6 DIGITS' onChange={onChange} style={!isCodeValid ? { color: "#ED7878" } : {}} />

            {(isLoading) && <img src="getsound/images/loading-animation.gif" alt="" />}
          </div>
          <div>
            <div className='text-lxl text-center'>
              <a href="#" onClick={(e) => {
                if (sendAgainTime > 0) return;

                e.preventDefault();
                onSendAgain();
                startSendAgainCountdown();
                return false;
              }} style={{
                textDecoration: "underline",
                color: sendAgainTime > 0 ? "#777777" : "#fff",
              }}>send again</a> {sendAgainTime > 0 && <span style={{ color: "#777777" }}>(in {sendAgainTime} sec)</span>}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
