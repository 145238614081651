import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ModalType } from '../types';
import { fadeModal, hideModal } from '../reducers/appSlice';
import showModalAction, { modalQueue } from './showModalAction';

export default createAsyncThunk<any, any, { state: RootState }>(
  'app/modalHide',
  async (modalToHide: ModalType | null | 'all', { dispatch, getState }) => {
    const state = getState();
    // console.log(`hiding modal ${modalToHide}`);
    if (!state.app.modal.type) return;

    if (modalToHide) {
      if (modalToHide === state.app.modal.type) {
        dispatch(hideModal());
      }
      if (modalQueue.find((modal) => modal.type === modalToHide)) {
        modalQueue = modalQueue.filter((modal) => modal.type !== modalToHide);
      }
      if (modalToHide === 'all') {
        dispatch(hideModal());
        while (modalQueue.length) {
          modalQueue.splice(0, 1);
        }
      }
      if (modalQueue.length > 0) {
        dispatch(showModalAction(modalQueue.shift()));
      }
    } else {
      dispatch(fadeModal(true));

      setTimeout(() => {
        dispatch(hideModal());
        dispatch(fadeModal(false));

        if (modalQueue.length > 0) {
          dispatch(showModalAction(modalQueue.shift()));
        }
      }, 300);
    }
  }
);
