import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import updateUserAction from './updateUserAction';
import {
  setShowHomeVolume,
  showZeroSoundNotification,
} from '../reducers/appSlice';
import {
  updateIsMainPlaying,
  updatePlayerVolume,
} from '../reducers/playerSlice';
import { PlayerType, VolumeUpdateSource } from '../types';

let suspendDebounceTimer: NodeJS.Timeout;

interface ChangeVolumeProps {
  type: PlayerType;

  value?: number;
  change?: number;

  source: VolumeUpdateSource;

  action?: 'mute';
}

export default createAsyncThunk<
  unknown,
  ChangeVolumeProps,
  { state: RootState }
>('player/changeVolume', async (data, { dispatch, getState }) => {
  const { type, value, change, source, action } = data;
  const { player } = getState();

  let newValue = 0;

  if (action === 'mute') {
    newValue = -player.volume[type];
  } else {
    if (value) {
      newValue = parseFloat((value / 100).toFixed(2));
    } else if (change) {
      newValue = player.volume[type] + change;
    }
    if (newValue < 0) newValue = 0;
    if (newValue > 1) newValue = 1;
  }

  if (newValue === player.volume[type]) return;

  if (type === PlayerType.Weather && !player.userWeatherSoundState) {
    return;
  }

  if (type === PlayerType.Main) {
    const stopMain = newValue <= 0.02;
    if (player.isMainPlaying !== !stopMain) {
      dispatch(updateIsMainPlaying(!stopMain));
    }
    clearTimeout(suspendDebounceTimer);
    if (player.isPlaying) {
      suspendDebounceTimer = setTimeout(() => {
        dispatch(showZeroSoundNotification(stopMain));
      }, 2 * 1000);
    }
  }

  if (source === VolumeUpdateSource.Keyboard) {
    dispatch(setShowHomeVolume(Date.now()));
    if (type === PlayerType.Main) {
      dispatch(showZeroSoundNotification(newValue <= 0.02));
    }
  }

  const param = `last_${type}_vol`;
  dispatch(updatePlayerVolume({ [type]: newValue, source }));
  dispatch(updateUserAction({ [param]: newValue }));

  // if (data.source !== 'tray') {
  //   dispatch(notifyTrayAction());
  // }
});
