import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PaymentPeriod,
  PaymentPlan,
  UserData,
  UserPreferences,
  UserSlice,
  UserInfo,
} from '../types';

const initialStatsState: UserSlice = {
  general: {
    first_name: '',
    last_name: '',
    email: '',
    profile_picture: '',
    uid: '',
    created_at: '',
    city: '',
    country: '',
    time_of_day: '',
    current_hour: 10,
    current_min: '',
  },
  preferences: {
    temp_unit: 'c',
    last_main_vol: 0.5,
    last_env_vol: 0.5,
    last_weather_vol: 0.5,
  },
  restrictions: {
    refresh_abuse: false,
    daily_usage: 0,
    daily_usage_limit: 0,
    usage_limit_reached: false,
    should_get_watermark: false,
  },
  plan_info: {
    zone_abuse: false,
    current_plan: PaymentPlan.Free,
    zones_allowed: 0,
    subscription_interval: PaymentPeriod.Monthly,
  },
  global_settings: {
    max_env_volume: 1,
    max_weather_volume: 1,
    max_main_volume: 1,
    update_check_interval_min: 60,
  },
  current_machines: [],
  show_quiz: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialStatsState,
  reducers: {
    updateUserData: (state, action: PayloadAction<UserData>) => {
      if (action.payload) {
        state.general = { ...state.general, ...action.payload.general };
        state.plan_info = { ...state.plan_info, ...action.payload.plan_info };
        state.restrictions = {
          ...state.restrictions,
          ...action.payload.restrictions,
        };
        state.preferences = {
          ...state.preferences,
          ...action.payload.preferences,
        };
        state.global_settings = {
          ...state.global_settings,
          ...action.payload.global_settings,
        };
        state.current_machines = action.payload.current_machines;
      }
    },
    updateUserGeneral: (state, action: PayloadAction<UserInfo>) => {
      state.general = {
        ...state.general,
        ...action.payload,
      };
    },
    updateUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
      state.preferences = {
        ...state.preferences,
        ...action.payload,
      };
    },
  },
});

export const { updateUserData, updateUserGeneral, updateUserPreferences } =
  userSlice.actions;

export default userSlice.reducer;
