import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { openMenu, weatherOutdated } from '../reducers/appSlice';
import { HomeViewType, ModalType, PaymentPlan } from '../types';
import getSoundDataAction from './getSoundAction';
import showModalAction from './showModalAction';
import reportErrorAction from './reportErrorAction';
import { StoreKey } from '../store-keys';
import { getStoreData, sendAnalyticsEvent } from '../../platform-utils/utils';

export default createAsyncThunk<any, boolean, { state: RootState }>(
  'app/showModalAction',
  async (isForce, { dispatch, getState }) => {
    const state = getState();

    if (state.player.isRefreshingSound || state.player.isCrossfading) return;
    const current_track = state.stats.playerStats.currentTrack;

    if (isForce) {
      dispatch(weatherOutdated(true));
      dispatch(getSoundDataAction());
      sendAnalyticsEvent('refresh', { current_track });
    } else {
      try {
        const isRefreshAbuse =
          state.user.plan_info.current_plan === PaymentPlan.Free &&
          state.user.restrictions.refresh_abuse;
        if (
          state.player.isPlaying &&
          state.app.homeView === HomeViewType.Home &&
          !isRefreshAbuse
        ) {
          dispatch(openMenu(false));
          if (await getStoreData(StoreKey.ShowRefreshModal)) {
            await dispatch(getSoundDataAction());
            sendAnalyticsEvent('refresh', { current_track });
          } else {
            dispatch(showModalAction({ type: ModalType.UserRefresh }));
          }
        }
      } catch (e: unknown) {
        dispatch(
          reportErrorAction(`Failed to refreshSoundAction ${e.message}`)
        );
      }
    }
  }
);
