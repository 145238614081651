import './Forgot.scss';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { MdMail } from 'react-icons/md';
import { HiLockClosed, HiOutlineMail } from 'react-icons/hi';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../constants';
import CircleButton from '../../../components/common/circle-button/CircleButton';
import { confirmForgotPassword, forgotPassword } from '../../../api/auth-api';
import Button from '../../../components/common/abstract-button/Button';
import ConfirmCode from '../ConfirmCode';
import Input from '../../../components/common/input/Input';
import Logo from "../../../assets/images/icon-logo-white.svg";
import IntroLogo from "../../../assets/images/intro-logo.png";
import ConfirmCodeInline from '../ConfirmCodeInline';
import { isDesktop } from 'react-device-detect';

interface IFormInputs {
  email: string;
  code: string;
  password: string;
  repeatPassword: string;
}

type Steps = 'password' | 'email' | 'new-password';

const Forgot = () => {


  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [sendCodeSuccess, setSendCodeSuccess] = useState(false);
  const [forgotError, setForgotError] = useState(false);
  const [forgotErrorMessage, setForgotErrorMessage] = useState<
    string | undefined
  >();
  const [step, setStep] = useState<Steps>('email');
  const [status, setStatus] = useState('Awaiting confirmation');
  const [successError, setSuccessError] = useState();

  const [userData, setUserData] = useState({
    email: location.state ? location.state.email : '',
    password: "",
    repeatPassword: "",
    code: "",
  })

  const onSendVerificationCode = async (e) => {
    e.preventDefault();
   
    // While sending verification code
    if (step === 'email') {
      setIsLoading(true);
      try {
        const response = await forgotPassword(userData.email.toLowerCase());
        console.log(response);
        setIsLoading(false);
        if (response?.success) {
          console.log('Forgot password code success!');
          setSendCodeSuccess(true);
          setForgotError(false);
          setStep('new-password');
        } else {
          console.log('error forgot password 1');
          setIsLoading(false);
          setForgotError(true);
          setForgotErrorMessage(response?.message);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    if (step === 'new-password') {
      setIsLoading(true);
      // While confirming code and change password
      try {
        const data = {
          email: userData.email.toLowerCase(),
          password: userData.password,
          code: userData.code,
        };
        const response = await confirmForgotPassword(data);
        console.log(response);

        // While forgot password change successful
        if (response.success) {
          console.log('Forgot password success!');
          setForgotError(false);

          // Log user in
          try {
            Auth.signIn(
              userData.email.toLowerCase(),
              userData.password
            ).then(() => {
              if (isDesktop) {
                navigate("/home")
              } else {
                navigate("download-screen");
              }
            })

            // setIsLoading(false);
          } catch (e) {
            console.log('error signing in', e);
            setIsLoading(false);
          }
        } else {
          console.log('error forgot password 2');
          setIsLoading(false);
          setForgotError(true);
          setForgotErrorMessage(response.message);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    return false;
  };

  const onBack = () => {
    navigate('/login', {
      state: {
        email: userData.email,
      },
    });
  };

  const onCode = (code: string) => {

    setUserData(prev => ({ ...prev, code }))
  };

  const onSendAgain = async () => {
    const res = await forgotPassword(userData.email);
  };



  const submitEnabled = step === 'email' && EMAIL_REGEX.test(userData.email);
  const submit2Enabled = step === 'new-password' && PASSWORD_REGEX.test(userData.password) && userData.password === userData.repeatPassword && userData.code.length === 6;

  return (
    <div className="forgot-screen">
      <div className="sideblock">
        <div className="header">
          <a href="#" onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}>
            <img src={IntroLogo} alt="" className='intro-logo' />
          </a>
        </div>


        <h1 className="font-light text-3xl" style={{
          margin: "20px 0 13px"
        }}>Password recovery</h1>
        <div className='font-light text-base'>Please follow the steps below to reset your password</div>

        {/* The Form */}
        <div className="forgot-form">
          <form onSubmit={onSendVerificationCode}>
            {/* Email */}



            <div className="label">
              Type your GetSound email Account
            </div>
            <Input
              icon={<MdMail color="#808080" size={18} />}
              type="text"

              value={userData.email}
              onChange={(value) => setUserData(prev => ({ ...prev, email: value }))}
              placeholder="Email"
              inputStyle="form-in"

              disabled={step !== 'email'}
            />



            {step === 'new-password' && (
              <div style={{
                margin: "35px 0 0"
              }}>
                <ConfirmCodeInline
                  email={userData.email}
                  showLoading={false}
                  onCode={onCode}
                  onSendAgain={onSendAgain}
                  onBack={onBack}
                />


                <div className="form new-password">
                  <Input
                    icon={<HiLockClosed color="#808080" size={18} />}
                    type="password"
                    value={userData.password}
                    onChange={(value) => setUserData(prev => ({ ...prev, password: value }))}
                    placeholder="Choose New Password"
                    inputStyle="form-in"
                    variant='dark'
                  />

                  <ul className='validation'>
                    <li className={userData.password.length >= 8 ? "valid" : "invalid"} >At least 8 characters</li>
                    <li className={/[0-9]/.test(userData.password) ? "valid" : "invalid"}>At least one number (0-9)</li>
                    <li className={/[a-z]/.test(userData.password) ? "valid" : "invalid"}>At least one lowercase</li>
                    <li className={/[A-Z]/.test(userData.password) ? "valid" : "invalid"}>At least one uppercase</li>
                  </ul>

                  <Input
                    icon={<HiLockClosed color="#808080" size={18} />}
                    type="password"
                    value={userData.repeatPassword}
                    onChange={(value) => setUserData(prev => ({ ...prev, repeatPassword: value }))}
                    placeholder="Repeat Password"
                    inputStyle="form-in"
                    variant='dark'
                  />

                  <ul className='validation'>
                    <li className={(userData.password && userData.password === userData.repeatPassword) ? "valid" : "invalid"} >Passwords match</li>
                  </ul>

                </div>
              </div>
            )}

            {/* Forgot Error */}
            {forgotError ? (
              <div className="forgot-error">{forgotErrorMessage}</div>
            ) : null}

            {step === 'email' && (
              <div className="forgot-form-submit">
                <Button disabled={!submitEnabled || isLoading} type='submit' variant='green' isActive={isLoading} >Continue</Button>
              </div>
            )}

            {step === 'new-password' && (
              <div className="forgot-form-submit">
                <Button disabled={!submit2Enabled || isLoading} type='submit' variant='green' isActive={isLoading} >Continue</Button>
              </div>
            )}



            <div className="back-to-login font-light">
              Back to <a onClick={onBack}>Login</a>
            </div>

          </form>
        </div>

      </div>
    </div>
  );
};

export default Forgot;
