import {
  AnyAction,
  combineReducers,
  configureStore,
  Store,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import authReducer from './reducers/authSlice';
import playerReducer from './reducers/playerSlice';
import appReducer from './reducers/appSlice';
import statsReducer from './reducers/statsSlice';
import userReducer from './reducers/userSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  app: appReducer,
  stats: statsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
  dispatch: AppThunkDispatch;
};

const store: AppStore = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
