import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useState } from 'react';
import { useAppDispatch } from '../redux/store';
import reportErrorAction from '../redux/actions/reportErrorAction';
import { sendAnalyticsEvent } from '../platform-utils/utils';
import { AnalyticsEvent } from '../redux/service/analytics-events';

const useOpenIdLogin = (beforeLogin = null) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSignInFacebook = async () => {
    setIsLoading(true);
    localStorage.setItem("social-login", true);
    if (beforeLogin) {
      beforeLogin();
    }
    // sendAnalyticsEvent(AnalyticsEvent.Login, { value: 'facebook' });
    try {
      sendAnalyticsEvent("signup", { provider: "facebook" });
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });
    } catch (e: any) {
      dispatch(reportErrorAction(`Failed to AUTH ${e.message}`));
      console.log(e);
    }
    setIsLoading(false);
  };

  const onSignInGoogle = async () => {
    setIsLoading(true);
    localStorage.setItem("social-login", true);
    if (beforeLogin) {
      beforeLogin();
    }
    // sendAnalyticsEvent(AnalyticsEvent.Login, { value: 'google' });
    console.log('Google Sign In');
    try {
      sendAnalyticsEvent("signup", { provider: "google" });

      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (e: any) {
      dispatch(reportErrorAction(`Failed to AUTH ${e.message}`));
      console.log(e);
    }
    setIsLoading(false);
  };

  const onSignInApple = async () => {
    setIsLoading(true);
    localStorage.setItem("social-login", true);
    if (beforeLogin) {
      beforeLogin();
    }
    // sendAnalyticsEvent(AnalyticsEvent.Login, { value: 'apple' });
    try {
      sendAnalyticsEvent("signup", { provider: "apple" });

      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Apple,
      });
      setIsLoading(false);
    } catch (e: any) {
      dispatch(reportErrorAction(`Failed to AUTH ${e.message}`));
    }
    setIsLoading(false);
  };

  const onSignInAmazon = async () => {
    setIsLoading(true);
    localStorage.setItem("social-login", true);
    if (beforeLogin) {
      beforeLogin();
    }
    // sendAnalyticsEvent(AnalyticsEvent.Login, { value: 'amazon' });
    try {
      sendAnalyticsEvent("signup", { provider: "amazon" });

      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Amazon,
      });

    } catch (e: any) {
      dispatch(reportErrorAction(`Failed to AUTH ${e.message}`));
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onSignInAmazon,
    onSignInFacebook,
    onSignInApple,
    onSignInGoogle,
  };
};

export default useOpenIdLogin;
