import React, { FC, useEffect, useState } from "react";
import "./affiliate-admin-screen.scss";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Input from "../components/input/Input";
import Button from "../components/button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IntroLogo from "../assets/images/intro-logo.png";
import { getStoreDataSync, setStoreData } from "platform-utils/utils";
import { StoreKey } from "redux/store-keys";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const newAffiliateData = () => {

  return {
    first_name: "",
    last_name: "",

    email: "",
    discount: 50,
    pay_factor: 1,
    plan_drop_id: 100,
  }
}

// 15W6pP5dxg4slVNuv2kUk2znOowwGUKX5NbY1vqi
const AffiliateAdminScreen = () => {
  const key = localStorage.getItem('key');
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!key) {
      navigate("/admin/login");
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  let machineId = getStoreDataSync(StoreKey.DeviceId);
  if (!machineId) {
    machineId = Math.random().toString(36) + Math.random().toString(36) + Math.random().toString(36),
      setStoreData(StoreKey.DeviceId, machineId);
  }

  const onSave = (data: IAffiliateData) => {
    setIsLoading(true);
    axios.post("https://api.getsound.ai/partners/create-affiliate", {
      ...data,
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
        "machine_id": machineId,
      }
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          getData();
          setShowForm(false);
        } else {
          setFormError(response.data.message);
          // setError(response.data.message);
        }

      })
      .catch(() => {
        setIsLoading(false);
      })

  }

  const [isForbidden, setIsForbidden] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios.get("https://api.getsound.ai/partners/list-affiliate", {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
        "machine_id": machineId,
      }
    })
      .then((response) => {
        setAffiliateList(response.data.affiliate);
        setIsLoading(false);
      })
      .catch(() => {
        setIsForbidden(true);
        setIsLoading(false);
      })
  }


  useEffect(() => {
    getData()
  }, []);

  const [affiliateList, setAffiliateList] = useState<IAffiliateData[]>([]);
  const [affiliateData, setAffiliateData] = useState<IAffiliateData | undefined>();

  const [formError, setFormError] = useState("");

  return <div className="screen affiliate-admin-screen">

    <div className="header">
      <a href="#" onClick={() => {
        window.location.href = "/";
      }}>
        <img src={IntroLogo} alt="" className='intro-logo' />
      </a>

      <button style={{
        position: "absolute",
        top: 20,
        right: 20,
        zIndex: 100,
        background: "transparent",
        border: "none",
        color: "#fff",
        textDecoration: "underline",
        fontSize: "15px",
      }} onClick={() => {
        localStorage.removeItem('key');
        navigate("/admin/login");
      }}>Logout</button>
    </div>

    <div className="content">
      {isForbidden && <div className="forbidden">
        <h1>Forbidden</h1>
        <p style={{
          color: "#fff",
        }}>You don't have access to this page.</p>
      </div>}

      {isLoading && <div className="loading">
        <div className="spinner"></div>
      </div>
      }

      {showForm && !isForbidden && <AffiliateForm error={error} isLoading={isLoading} data={affiliateData} onSave={onSave} onClose={() => {
        setShowForm(false);
        setAffiliateData(undefined);
      }} />}


      {!showForm && !isForbidden && <div className="affiliate-list">

        <div className="head">
          <h1>
            <div>Affiliates</div>
          </h1>
          <Button variant="green" onClick={() => {
            setAffiliateData(undefined);
            setShowForm(true);

          }}>Create new affiliate</Button>
        </div>


        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Partner</th>
                <th>Discount</th>
                <th>Email</th>
                <th>Url</th>
                <th>Signups</th>
                <th style={{ textAlign: "center" }}>Sales</th>
                <th>Pay factor</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {affiliateList.map((affiliate) => {
                return <tr key={affiliate.id}>
                  <td>
                    {affiliate.first_name} {affiliate.last_name}
                  </td>
                  <td className="center">
                    {affiliate.discount}%
                  </td>
                  <td>
                    {affiliate.email}
                  </td>
                  <td>
                    <a style={{
                      color: "#fff",
                    }} href={`https://app.getsound.ai/${affiliate.url}`} target="_blank" rel="noreferrer">{affiliate.url}</a>
                  </td>
                  <td className="center">
                    {affiliate.signups}
                  </td>
                  <td>
                    <div className="profit-data">
                      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                        <div>PM: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'personal' && _.plan_interval === 'month').length}</span></div>
                        <div>PY: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'personal' && _.plan_interval === 'year').length}</span></div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                        <div>BM: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'business' && _.plan_interval === 'month').length}</span></div>
                        <div>BY: <span style={{ color: "#16CFA3" }}>{affiliate.sales.filter(_ => _.plan_name === 'business' && _.plan_interval === 'year').length}</span></div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center" }}>
                        Profit: <span style={{ color: "#16CFA3" }}>${affiliate.sales.reduce((acc, sale) => {
                          return acc + sale.profit;
                        }, 0)}</span>
                      </div>
                    </div>
                  </td>
                  <td className="center">
                    {affiliate.pay_factor}
                  </td>
                  {/* <td>
                    <Button variant="green" onClick={() => {
                      setAffiliateData(affiliate);
                      setShowForm(true);
                    }}>Edit</Button>

                  </td> */}
                </tr>
              })}

            </tbody>
          </table>
        </div>
      </div>
      }
    </div>

    {formError && <Popup text={formError} onClose={() => {
      setFormError("");
    }}
    />}
  </div>
}


interface IAffiliateData {
  id?: string;
  url?: string; //
  first_name: string; //
  last_name: string; //
  email: string; //
  paypal_email: string; //
  discount: number;

  instagram: string;//
  facebook: string; //

  youtube: string; //
  tiktok: string; //
  twitter: string;

  website: string; //
  blog: string; //

  description: string; //
  plan_drop_id: number;
  pay_factor: number;
  signups?: string;
  sales?: [];
  signed_date?: string;
  created_at?: string;
  updated_at?: string;
  active?: boolean;
}

interface AffiliateFormProps {
  data?: IAffiliateData;
  onSave: (data: IAffiliateData) => void;
  onClose: () => void;
  isLoading: boolean;
  error: string;
}


const REQUIRED = [
  'first_name',
  'last_name',
  'email',
  'discount',
  'plan_drop_id',
  'pay_factor',
];

const AffiliateForm: FC<AffiliateFormProps> = ({ data, onSave, error: errorMsg, onClose, isLoading }) => {

  const [affiliateData, setAffiliateData] = useState<IAffiliateData>({} as IAffiliateData);

  const onChange = (field: keyof IAffiliateData) => (value: any) => {
    setAffiliateData(prev => ({ ...prev, [field]: value }))

    if (["email"].indexOf(field) !== -1) {
      if (!EMAIL_REGEX.test(value)) {
        setError("Invalid email");
        return;
      } else {
        setError("");
      }
    }
    if (["paypal_email"].indexOf(field) !== -1) {
      if (!EMAIL_REGEX.test(value)) {
        setError("Invalid Paypal email");
        return;
      } else {
        setError("");
      }
    }
  }
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (data) {
      setIsNew(false);
      setAffiliateData(data);
    } else {
      setIsNew(true);
      setAffiliateData(newAffiliateData());
    }
  }, [data]);

  const [error, setError] = useState("");

  const onSaveClick = () => {

    const missing = REQUIRED.filter((field) => {
      return !affiliateData[field as keyof IAffiliateData];
    });

    if (missing.length) {
      setError(`Missing fields: ${missing.join(", ")}`);
      return;
    }

    onSave(affiliateData);
  }

  return <div className="affiliate-form">

    <button className="close-button" onClick={onClose}>
      <IoIosCloseCircleOutline color="#fff" size={24} />
    </button>

    <h1>{isNew ? 'Create new affiliate' : 'Edit affiliate'}</h1>

    <div className="input-row">
      <Input value={affiliateData?.first_name ?? ""} placeholder="First name" onChange={onChange("first_name")} type={"text"} />
      <Input value={affiliateData?.last_name ?? ""} placeholder="Last name" onChange={onChange("last_name")} type={"text"} />
    </div>

    <div className="input-row">
      <div className="input-wrapper">
        <div className="label">Discount</div>
        <select value={affiliateData.discount} name="" id="" onChange={(e) => {
          setAffiliateData(prev => ({ ...prev, discount: parseInt(e.target.value) }))
        }}>
          <option value="50">50%</option>
        </select>
      </div>

      <div className="input-wrapper">
        <div className="label">Plan</div>
        <select value={affiliateData.plan_drop_id} name="" id="" onChange={(e) => {
          setAffiliateData(prev => ({ ...prev, plan_drop_id: parseInt(e.target.value) }))
        }}>
          <option value="100">Personal Monthly</option>
          <option value="101">Personal Yearly</option>
          <option value="200">Small Buisness Monthly</option>
          <option value="201">Small Buisness Yearly</option>
        </select>
      </div>

      <div className="input-wrapper">
        <div className="label">Pay factor</div>
        <select value={affiliateData.pay_factor} name="" id="" onChange={(e) => {
          setAffiliateData(prev => ({ ...prev, pay_factor: parseInt(e.target.value) }))
        }}>
          <option value="1">1</option>
          <option value="2">2</option>

        </select>
      </div>
    </div>

    {/* <div className="separator"></div> */}

    <div className="input-row">
      <Input value={affiliateData?.email ?? ""} placeholder="Email" onChange={onChange("email")} type={"text"} />
      <Input value={affiliateData?.paypal_email ?? ""} placeholder="Paypal Email" onChange={onChange("paypal_email")} type={"text"} />
    </div>

    <div className="input-row">
      <Input value={affiliateData?.instagram ?? ""} placeholder="Instagram" onChange={onChange("instagram")} type={"text"} />
      <Input value={affiliateData?.facebook ?? ""} placeholder="Facebook" onChange={onChange("facebook")} type={"text"} />
      <Input value={affiliateData?.youtube ?? ""} placeholder="Youtube" onChange={onChange("youtube")} type={"text"} />
    </div>
    <div className="input-row">
      <Input value={affiliateData?.tiktok ?? ""} placeholder="Tiktok" onChange={onChange("tiktok")} type={"text"} />
      <Input value={affiliateData?.twitter ?? ""} placeholder="Twitter" onChange={onChange("twitter")} type={"text"} />
    </div>
    <div className="input-row">
      <Input value={affiliateData?.website ?? ""} placeholder="Website" onChange={onChange("website")} type={"text"} />
      <Input value={affiliateData?.blog ?? ""} placeholder="Blog" onChange={onChange("blog")} type={"text"} />
    </div>

    <div className="input-row">
      <Input multiline value={affiliateData?.description ?? ""} placeholder="Description" onChange={onChange("description")} type={"text"} />
    </div>

    <div className="error">{error}</div>
    <div className="error">{errorMsg}</div>

    <div className="mt-1">
      <Button type="submit" variant="green" disabled={isLoading} onClick={onSaveClick}>Save</Button>
    </div>

  </div>
}

export default AffiliateAdminScreen;

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    console.log('Async: Copying to clipboard was successful!');
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

const Popup = ({ text, onClose }) => {

  return <div className="popup-overlay" onClick={onClose}>

    <div className="popup" onClick={(e) => {
      e.stopPropagation();
    }}>
      <button className="close-button" onClick={onClose}>
        <IoIosCloseCircleOutline color="#fff" size={30} />
      </button>

      <div className="message">
        {text}
      </div>



      <Button variant="green" onClick={() => {
        onClose();
      }}>Ok</Button>

    </div>
  </div>

}