import axios from 'axios';
import { SingUpResponse } from '../redux/types';
import { API_BASE_URL } from '../constants';
import { StoreKey } from '../redux/store-keys';
import { getStoreData } from '../platform-utils/utils';

// const deviceId = getStoreData(StoreKey.AppData);

export const authApiClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    // machine_id: deviceId,
  },
});

interface ForgotPasswordResponse {
  success: boolean;
  message?: string;
}

export const getPartnersData = async (affiliate_url: string) => {

  const response = await authApiClient.get(`/partners/ref-info?affiliate_url=${affiliate_url}&lang=en`)
  return response.data;

};

export const forgotPassword = async (
  email: string
): Promise<ForgotPasswordResponse | null> => {
  try {
    const response = await authApiClient.post('/user/forgot-password', {
      email,
    });
    return response.data;
  } catch (e) {
    return null;
  }
};

export const signUp = async (data: any): Promise<SingUpResponse> => {
  const response = await authApiClient.post('/user/signup', data);
  return response.data;
};

export const sendEmailVerification = async (
  email: string
): Promise<SingUpResponse> => {
  try {
    const response = await authApiClient.post('/user/resend-code', { email });
    return response.data;
  } catch (e: any) {
    return e;
  }
};

interface ConfirmForgotPasswordResponse {
  success: boolean;
  message?: string;
}

export const confirmForgotPassword = async (
  data: any
): Promise<ConfirmForgotPasswordResponse | null> => {
  try {
    const response = await authApiClient.post(
      '/user/confirm-forgot-password',
      data
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

interface ConfirmSignupResponse {
  success: boolean;
}

export const confirmSignup = async (
  data: any
): Promise<ConfirmSignupResponse | null> => {
  try {
    const response = await authApiClient.post('/user/confirm-code', data);
    return response.data;
  } catch (e) {
    return { success: false, message: e.message };
  }
};
