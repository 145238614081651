import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { interfaceLock } from '../reducers/appSlice';
import playerPlayAction from './playerPlayAction';

export default createAsyncThunk<any, any, { state: RootState }>(
  'app/lockInterface',
  async (reason, { dispatch }) => {
    dispatch(interfaceLock(reason));
    dispatch(playerPlayAction(false));
  }
);
