import { HTMLInputTypeAttribute, memo, ReactNode, useState } from 'react';
import './input.scss';

import { IoEye, IoEyeOff } from 'react-icons/io5';
import { useNoAcceleratorsInput } from '../../../hooks/useNoAcceleratorsInput';

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (e: any) => void;
  containerStyle?: string;
  inputStyle?: string;
  type: HTMLInputTypeAttribute | undefined;
  placeholder?: string;
  inputProps?: {};
  icon?: ReactNode;
  error?: boolean;
  variant?: 'dark' | 'light';
  disabled?: boolean;
}

export default memo((props: InputProps) => {
  const {
    onChange,
    onKeyDown = () => {},
    value,
    placeholder = '',
    containerStyle,
    inputStyle,
    type = 'text',
    inputProps = null,
    icon,
    error,
    variant = 'light',
    disabled = false,
  } = props;
  const onChangeAction = (e) => {
    onChange(e.target.value);
  };

  const [showValue, setShowValue] = useState(false);

  const inputType =
    type === 'password' ? (showValue ? 'text' : 'password') : type;

  const noAccelerators = useNoAcceleratorsInput();

  return (
    <div className={`input-wrapper ${containerStyle} ${error ? 'error' : ''}`}>
      {icon && <div className="icon">{icon}</div>}

      <input
        {...noAccelerators}
        type={inputType}
        onChange={inputProps ? null : onChangeAction}
        value={value}
        className={` ${inputStyle} ${error ? 'error' : ''}`}
        placeholder={placeholder}
        disabled={disabled}
        {...inputProps}
        onKeyDown={onKeyDown}
      />

      {type === 'password' && (
        <button
          className={`eye ${showValue ? 'active' : null}`}
          type="button"
          onClick={() => {
            setShowValue((prev) => !prev);
          }}
        >
          {showValue ? (
            <IoEye color={variant === 'dark' ? '#808080' : '#31394F'} size={20} />
          ) : (
            <IoEyeOff
              color={variant === 'dark' ? '#808080' : '#31394F'}
              size={20}
            />
          )}
        </button>
      )}
    </div>
  );
});
