import './App.scss';
import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Auth, Hub } from 'aws-amplify';
import { StoreKey } from './redux/store-keys';
import Navigation from './components/AppNavigation';
import { useAppDispatch, useAppSelector } from './redux/store';
import { authLoadingState, userLoginCheck } from './redux/reducers/authSlice';
import ModalService from './components/modals/ModalService';
import { apiClient } from './api/api';
import { ModalType } from './redux/types';
import AppUpdate from './components/AppUpdate';
import showModalAction from './redux/actions/showModalAction';
import hideModalAction from './redux/actions/hideModalAction';
import {
  getStoreData,
  setStoreData,
} from './platform-utils/utils';
import useNetworkState from './hooks/useNetworkState';
import loadUserDataAction from './redux/actions/loadUserDataAction';
import { getAppData } from './redux/selectors';
import { AnalyticsEvent } from './redux/service/analytics-events';

export default function App() {
  const dispatch = useAppDispatch();
  const { isLoggedIn, deviceId } = useAppSelector(getAppData);

  // restore from closed
  const getUser = async () => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (e) {
      console.log(e, 'Not signed in');
      return false;
    }
  };

  const onAuth = async (userData: any) => {
    let appData = await getStoreData(StoreKey.AppData);

    let deviceId = await getStoreData(StoreKey.DeviceId);
    if (!deviceId) {
      deviceId = Math.random().toString(36) + Math.random().toString(36) + Math.random().toString(36);
      setStoreData(StoreKey.DeviceId, deviceId);
    }


    if (appData && appData.userTokenData) {
      console.log('Has user app data');
    } else {
      appData = {};
    }

    if (userData) {
      // Update token
      appData.userTokenData = {
        accessToken: userData.signInUserSession.accessToken.jwtToken,
        idToken: userData.signInUserSession.idToken.jwtToken,
        refreshToken: userData.signInUserSession.refreshToken.token,
        deviceId,
      };

      apiClient.defaults.headers.common.machine_id = deviceId;
      apiClient.defaults.headers.common.Authorization = `Bearer ${userData.signInUserSession.idToken.jwtToken}`;

    
    } else {
      appData.userTokenData = null;
    }

    setStoreData(StoreKey.AppData, appData);

    // Update login
    dispatch(authLoadingState(false));
    dispatch(userLoginCheck(appData));
    return true;
  };

  const auth = useCallback(() => {
    getUser()
      .then((userData) => {
        // debugger
        onAuth(userData);
        return null;
      })
      .catch((e) => console.log);
  }, []);

  useEffect(() => {


    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'codeFlow':
          dispatch(authLoadingState(true));
          break;
        case 'signIn':
        case 'cognitoHostedUI':
          auth();
          break;
        case 'signOut':
          // do nothing
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });
    auth();

    return unsubscribe;
  }, [dispatch, auth]);

  return (
    <>
      <Navigation />
      <ModalService />
      <AppUpdate />
    </>
  );
}


