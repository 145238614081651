import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoreKey } from '../store-keys';
import { RootState } from '../store';
import {
  pushPlaylist,
  toggleWatermark,
  updateRefreshing,
  setUserEnv,
  updateUserWeatherState,
  updatePlayerVolume,
} from '../reducers/playerSlice';
import { getPaymentPlans, getSound, updateUsage } from '../../api/api';
import { ModalType, UsageStatus, VolumeUpdateSource } from '../types';
import {
  setInitialLoading,
  interfaceLock,
  setBackground,
  showSplash,
  updateDeviceName,
  updateInterfaceData,
  weatherOutdated,
  updatePaymentPlans,
} from '../reducers/appSlice';
import showModalAction from './showModalAction';
import logoutAction from './logoutAction';
import loadStubTracks from './loadStubTracks';
import reportErrorAction from './reportErrorAction';
import hideModalAction from './hideModalAction';
import playerPlayAction from './playerPlayAction';
import { updateUserData } from '../reducers/userSlice';
import {
  getStoreData,
  setStoreData,
  sendAnalyticsEvent,
  setAnalytcisUser,
} from '../../platform-utils/utils';
import { AnalyticsEvent } from '../service/analytics-events';
import notifyTrayAction from './notifyTrayAction';
import { PLATFORM } from '../../constants/config';
import updateUserAction from './updateUserAction';

let serverErrorCycle = false;

let request;

const getSoundDataAction = createAsyncThunk<
  unknown,
  void,
  { state: RootState }
>('player/getSound', async (_, { dispatch, getState }) => {
  const socialAffiliate = localStorage.getItem("social-affiliate");
  if (socialAffiliate) return;

  const state = getState();
  const { isInitialLoading } = state.app;
  let shouldPlayerPlay = false;
  dispatch(updateRefreshing(true));

  try {
    request = {
      api_ver: 2,
      lat: state.app.userCoordinates.lat,
      lng: state.app.userCoordinates.lng,
      platform: PLATFORM,
      current_ver: state.app.version ?? '0.0.0',
      include_weather: state.app.isWeatherOutdated,
      current_hour: state.user.general.current_hour ?? 10,
      weather_state: false,
    };

    const response = await getSound(request);

    if (response.success) {
      dispatch(hideModalAction(ModalType.ServerUpgrading));

      dispatch(interfaceLock(null));

      updateUsage(UsageStatus.Refresh);
      const {
        bg,
        soundscapes,
        user_data: userData,
        weather,
        side_menu_deal: sideMenuDeal,
      } = response;

      if (bg.image) {
        dispatch(setBackground(bg.image));
        dispatch(showSplash(false));
      } else {
        dispatch(setInitialLoading(false));
        dispatch(showSplash(false));
      }

      if (userData.show_quiz) {
        dispatch(showModalAction({ type: ModalType.Quiz }));
      }

      if (soundscapes) {
        dispatch(pushPlaylist(soundscapes));
        dispatch(updateUserWeatherState(soundscapes.weather));
        dispatch(toggleWatermark(soundscapes.watermark ?? false));
      }

      if (typeof weather === 'object' && Object.keys(weather).length !== 0) {
        dispatch(updateInterfaceData({ weather }));
      }
      dispatch(updateInterfaceData({ sideMenuDeal }));

      dispatch(updateUserData(userData));



      if (isInitialLoading) {
        dispatch(
          updatePlayerVolume({
            main: userData.preferences.last_main_vol,
            env: userData.preferences.last_env_vol,
            weather: userData.preferences.last_weather_vol,
            source: VolumeUpdateSource.Server,
          })
        );

        if (await getStoreData(StoreKey.AppRestartedBecauseOfUpdate)) {
          console.log('AppRestartedBecauseOfUpdate');
          shouldPlayerPlay = await getStoreData(StoreKey.PlayerIsPlaying);

          setStoreData(StoreKey.AppRestartedBecauseOfUpdate, null);
        } else {
          shouldPlayerPlay = userData.preferences.play_on_startup;
        }
      } else {
        shouldPlayerPlay = state.player.isPlaying;
      }

      if (!soundscapes) {
        const res = await getPaymentPlans();
        if (res?.success) {
          dispatch(updatePaymentPlans(res.payload));
        }

        dispatch(showModalAction({ type: ModalType.Runout }));
        shouldPlayerPlay = false;
      }

      if (userData.current_machines.length > 0) {
        const deviceName = userData.current_machines.find(
          (device) => device.machine_id === state.app.deviceId
        )?.machine_name;
        if (deviceName) {
          dispatch(updateDeviceName(deviceName));
          setStoreData(StoreKey.ZoneName, deviceName);
        }
      }

      dispatch(setUserEnv(userData.preferences.user_env));

      dispatch(weatherOutdated(false));
      dispatch(updateRefreshing(false));

      if (userData.plan_info.zone_abuse) {
        dispatch(
          showModalAction({
            type: ModalType.Terminate,
            data: { ...userData },
          })
        );
        shouldPlayerPlay = false;
      }

      setStoreData(
        StoreKey.UpdateCheckIntervalMin,
        userData.global_settings.update_check_interval_min
      );

      setStoreData(StoreKey.ClientIp, userData.general.ip);
      setStoreData(StoreKey.UserId, userData.general.uid);

      setStoreData(StoreKey.PaymentPlan, userData.plan_info.current_plan);

      if (userData) {
        setAnalytcisUser({
          ...userData,
        });
      }

      // shouldPlayerPlay
      // false for web in case user didnt interracted with dom
      dispatch(playerPlayAction(false));

      if (localStorage.getItem("ref") && !userData.general.signup_ref_id) {
        const referralId = localStorage.getItem("ref");
        dispatch(updateUserAction({ signup_ref_id: referralId }));
        localStorage.removeItem("ref");
      }
    } else {
      dispatch(logoutAction());
    }
    serverErrorCycle = false;
    return true;
  } catch (e: any) {
    if (e.code !== 'ERR_NETWORK') {
      dispatch(
        reportErrorAction(
          `Failed to getSound ${e.message} ${JSON.stringify(request)}`
        )
      );
    }
    if (!serverErrorCycle) {
      dispatch(showModalAction({ type: ModalType.ServerUpgrading }));
      dispatch(loadStubTracks());
      serverErrorCycle = true;
      setTimeout(() => {
        serverErrorCycle = false;
      }, 300 * 1000);
    }
    console.log(e);
    console.log('retrying in 5 seconds');
    setTimeout(() => {
      dispatch(getSoundDataAction());
    }, 5000);
  }

  return false;
});

export default getSoundDataAction;
