import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendAnalyticsEvent } from '../../platform-utils/utils';
import {
  AppStateSlice,
  HomeViewType,
  ModalData,
  ModalType,
  PaymentPlanInfo,
  TutorialEffects,
} from '../types';

const initailTutorialEffects = {
  tutorialHidden: false,
  menuHidden: true,
  weatherHidden: true,
  controlsHidden: true,
  volumeHidden: true,
  globeTip: false,
  envTip: false,
};

export const initialAppState: AppStateSlice = {
  appUpdating: true,
  isTutorialPassed: false,
  isFirstTime: true,
  tutorialEffects: { ...initailTutorialEffects },

  interfaceLocked: null,
  isInitialLoading: true,
  paymentPlans: [],

  recentlyUpdatedPlan: false,

  background: null,

  googleCoordinates: { lat: 0, lng: 0 },
  userCoordinates: { lat: 0, lng: 0 },
  isGlobe: false,

  menuOpen: false,

  showSplash: true,

  hideSplashStarted: false,
  suspendGetSoundData: false,
  showZeroSoundNotification: false,

  locationPickerEnabled: false,

  modal: {
    type: null,
    data: null,
  },
  fadeModal: false,
  homeView: HomeViewType.Home,
  isWeatherOutdated: true,

  interfaceData: {
    weather: null,
    sideMenuDeal: null,
  },

  notifications: null,

  version: null,
  deviceId: null,
  deviceName: null,

  showDebug: false,
  showHomeVolume: false,
  travelMode: false,
};

export const appSlice = createSlice({
  name: 'interface',
  initialState: initialAppState,
  reducers: {
    resetAppState: (state) => {
      state.isInitialLoading = true;
    },
    updateInterfaceData: (state, action) => {
      state.interfaceData = {
        ...state.interfaceData,
        ...action.payload,
      };
    },
    interfaceLock: (state, action: PayloadAction<null | ModalType>) => {
      if (window && window.electron) {
        if (action.payload) {
          window.electron.actions.lock();
        } else {
          window.electron.actions.unlock();
        }
      }
      state.menuOpen = false;
      state.interfaceLocked = action.payload;
    },
    readyForUpdate: (state) => {
      state.appUpdating = true;
    },
    updateFinished: (state) => {
      state.appUpdating = false;
    },
    updatePaymentPlans: (state, action: PayloadAction<PaymentPlanInfo[]>) => {
      state.paymentPlans = action.payload;
    },
    showModal: (state, action: PayloadAction<ModalData>) => {
      state.modal = action.payload;
      state.menuOpen = false;
    },
    hideModal: (state) => {
      state.modal = {
        type: null,
        data: null,
      };
    },
    fadeModal: (state, action) => {
      state.fadeModal = action.payload;
    },
    changeHomeView: (state, action: PayloadAction<HomeViewType>) => {
      state.homeView = action.payload;
      if (action.payload === HomeViewType.Home && state.interfaceLocked) {
        state.modal.type = state.interfaceLocked;
      }
    },
    updateGoogleCoordinates: (state, action) => {
      state.googleCoordinates = action.payload;
      state.userCoordinates = action.payload;
    },
    showZeroSoundNotification: (state, action) => {
      state.showZeroSoundNotification = action.payload;
    },
    updateUserCoordinates: (state, action) => {
      state.userCoordinates = action.payload;
    },
    useGoogleCoordinates: (state) => {
      state.userCoordinates = { ...state.googleCoordinates };
    },
    setIsFirstTime: (state) => {
      state.isFirstTime = true;
    },
    showSplash: (state, action) => {
      state.showSplash = action.payload;
    },
    setRecentlyUpdatedPlan: (state, action) => {
      state.recentlyUpdatedPlan = action.payload;
    },
    openMenu: (state, action) => {
      state.menuOpen = action.payload;
    },
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
    setInitialLoading: (state, action) => {
      state.isInitialLoading = action.payload;
    },
    hideSplash: (state, action) => {
      state.hideSplashStarted = action.payload;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload;
    },
    updateDeviceName: (state, action) => {
      state.deviceName = action.payload;
    },
    weatherOutdated: (state, action) => {
      state.isWeatherOutdated = action.payload;
    },
    showNotification: (state, action) => {
      state.notifications = action.payload;
    },
    hideNotification: (state) => {
      state.notifications = null;
    },
    setShowTutorial: (state, action) => {
      state.isTutorialPassed = false;
      state.tutorialEffects = { ...initailTutorialEffects };
      state.menuOpen = false;
    },
    isTutorialPassed: (state, action) => {
      state.isTutorialPassed = action.payload;
      if (action.payload) {
        state.tutorialEffects = null;
      }
    },
    updateTutorialEffects: (state, action: PayloadAction<TutorialEffects>) => {
      state.tutorialEffects = {
        ...state.tutorialEffects,
        ...action.payload,
      };
    },
    setShowHomeVolume: (state, action) => {
      state.showHomeVolume = action.payload;
    },
    suspendGetSoundData: (state, action) => {
      state.suspendGetSoundData = action.payload;
    },
    toggleDebug: (state) => {
      state.showDebug = !state.showDebug;
    },
    toggleLocationPicker: (state) => {
      state.locationPickerEnabled = !state.locationPickerEnabled;
    },
    setTravelMode: (state, action) => {
      state.travelMode = action.payload;
    },
    setBackground: (state, action) => {
      state.background = action.payload;
    },
    updateIsGlobe: (state, action) => {
      state.isGlobe = action.payload;
    },
  },
});

export const {
  updateIsGlobe,
  showModal,
  hideModal,
  fadeModal,
  updateFinished,
  readyForUpdate,
  updateInterfaceData,
  changeHomeView,
  interfaceLock,
  setVersion,
  setBackground,
  updateUserCoordinates,
  updateGoogleCoordinates,
  showSplash,
  openMenu,
  toggleMenu,
  setInitialLoading,
  useGoogleCoordinates,
  hideSplash,
  weatherOutdated,
  showNotification,
  hideNotification,
  isTutorialPassed,
  updateTutorialEffects,
  setRecentlyUpdatedPlan,
  updateDeviceName,
  setDeviceId,
  setIsFirstTime,
  suspendGetSoundData,
  resetAppState,
  showZeroSoundNotification,
  setShowHomeVolume,
  toggleDebug,
  toggleLocationPicker,
  setTravelMode,
  setShowTutorial,
  updatePaymentPlans,
} = appSlice.actions;

export default appSlice.reducer;
