import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { updateUsage } from '../../api/api';
import { UsageStatus } from '../types';
import { updateIsMainPlaying, updateIsPlaying } from '../reducers/playerSlice';
import { showZeroSoundNotification } from '../reducers/appSlice';
import notifyTrayAction from './notifyTrayAction';
import { sendAnalyticsEvent } from '../../platform-utils/utils';

export default createAsyncThunk<
  unknown,
  boolean | undefined,
  { state: RootState }
>('player/playPause', async (isPlaying, { dispatch, getState }) => {
  const { player, app } = getState();
  const newState = isPlaying === undefined ? !player.isPlaying : isPlaying;
  if (player.isPlaying !== newState) {
    sendAnalyticsEvent(
      newState ? (app.isInitialLoading ? 'play_auto' : 'play') : 'pause'
    );
  }
  updateUsage(newState ? UsageStatus.Play : UsageStatus.Pause);
  dispatch(updateIsPlaying(newState));

  if (newState) {
    if (player.volume.main <= 0.02) {
      dispatch(updateIsMainPlaying(false));
      dispatch(showZeroSoundNotification(true));
    }
  }

  if (window && window.electron) {
    window.electron.player.toggle(newState);
    dispatch(notifyTrayAction());
  }
});
