import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../../components/common/abstract-button/Button';
import './confirm-code.scss';

interface ConfirmCodeProps {
  email: string;
  onCode: (code: string) => void;
  onBack: () => void;
  onSendAgain: () => void;
  errorMessage?: string;
}

const DIGITS = 6;
const SEND_AGAIN_TIME = 60;

export default (props: ConfirmCodeProps) => {
  const { email, onCode, onBack, onSendAgain, errorMessage } = props;
  const [code, setCode] = useState('');
  const activeInput = useRef(0);

  const inputRefs = useRef({});

  const onChange = (index) => (e) => {
    if (code.length < DIGITS) {
      const newValue = `${code}${e.target.value}`;
      console.log('code', newValue);

      setCode(newValue);

      if (focusNext()) {
        console.log('end code input');
        console.log(newValue);
        document.activeElement.blur();
        onCode(newValue);
      }
    }
  };

  const onFocus = () => {
    console.log('active', activeInput.current);

    if (activeInput.current >= 0 && activeInput.current < DIGITS) {
      inputRefs.current[activeInput.current].current.focus();
    }
  };

  const focusNext = () => {
    const nextInput = activeInput.current + 1;
    if (nextInput < DIGITS) {
      setTimeout(() => {
        inputRefs.current[nextInput].current.focus();
      }, 25);
      activeInput.current = nextInput;
      return false;
    }
    return true;
  };

  const focusPrev = () => {
    const prevInput = activeInput.current - 1;
    if (prevInput >= 0) {
      activeInput.current = prevInput;
      setTimeout(() => {
        inputRefs.current[prevInput].current.focus();
      }, 25);
    }
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace': {
        const newValue = code.substring(0, code.length - 1);
        setCode(newValue);
        focusPrev();
        e.stopPropagation();
        e.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text/plain')
      .substring(0, DIGITS);

    setCode(pastedData);
    if (pastedData.length === DIGITS) {
      onCode(pastedData);
    }
  };

  const renderInputs = () => {
    const inputs = [];

    for (let i = 0; i < DIGITS; i += 1) {
      if (i === 3) {
        inputs.push(
          <span key="sep" className="separator">
            -
          </span>
        );
      }
      const ref = createRef();
      inputRefs.current[i] = ref;
      inputs.push(
        <input
          key={i}
          onChange={onChange(i)}
          ref={ref}
          onPaste={handlePaste}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          value={code[i] ?? ''}
          maxLength={1}
        />
      );
    }

    return inputs;
  };

  const sendAgainTimeRef = useRef(SEND_AGAIN_TIME);
  const [sendAgainTime, setSendAgainTime] = useState(SEND_AGAIN_TIME);
  const sendAgainCountdown = useRef<NodeJS.Timer>();

  const startSendAgainCountdown = () => {
    setSendAgainTime(SEND_AGAIN_TIME);
    sendAgainTimeRef.current = SEND_AGAIN_TIME;
    sendAgainCountdown.current = setInterval(() => {
      sendAgainTimeRef.current -= 1;

      if (sendAgainTimeRef.current > 0) {
        setSendAgainTime(sendAgainTimeRef.current);
      } else {
        clearInterval(sendAgainCountdown.current);
        setSendAgainTime(0);
      }
    }, 1000);
  };

  useEffect(() => {
    startSendAgainCountdown();

    return () => {
      clearInterval(sendAgainCountdown.current);
    };
  }, []);

  const onSendAgainAction = () => {
    if (sendAgainTimeRef.current <= 0) {
      onSendAgain();
      startSendAgainCountdown();
    }
  };

  return (
    <div className="confirm-code">
      <h3>Check your email for a confirmation code</h3>

      <p className="email">
        If <span style={{ color: '#000' }}>{email}</span> is a valid email,{' '}
        <br />
        you’ll receive a code. It expires in 10 minutes.
      </p>

      <p>Confirmation code</p>
      <div className="code-input">{renderInputs()}</div>

      <div className={`error ${errorMessage ? 'active' : ''}`}>
        {errorMessage}
      </div>

      <div className="support">
        Didn’t receive an email? <br /> Contact us at{' '}
        <a href="mailto:support@getsound.ai">support@getsound.ai</a> for
        assistance.
      </div>

      <div className="send-again">
        <Button
          onClick={onSendAgainAction}
          // isActive={isLoading === 'resend'}
          variant="link"
          disabled={sendAgainTime > 0}
        >
          Send again <br />
          {sendAgainTime > 0 && `(in ${sendAgainTime} sec)`}
        </Button>
      </div>

      <div className="go-back">
        <Button onClick={onBack} variant="link">
          back
        </Button>
      </div>
    </div>
  );
};
