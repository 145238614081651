export const useNoAcceleratorsInput = () => {
  const onFocus = () => {
    if (window.electron) {
      window.electron.actions.lock();
    } else {
      window.preventKeyBindings = true;
    }
  };

  const onBlur = () => {
    if (window.electron) {
      window.electron.actions.unlock();
    } else {
      window.preventKeyBindings = false;
    }
  };

  return {
    onFocus,
    onBlur,
  };
};
