/* eslint-disable import/prefer-default-export */
export enum AnalyticsEvent {
  Login = 'login',
  AutoLogin = 'app_opened',
  Logout = 'logout',
  ChangeLocation = 'change_location',
  ChangeEnvironment = 'change_env',

  Play = 'play',
  Pause = 'pause',
  Refresh = 'refresh',
  NotificationClose = 'notification_close',
  NotificationClick = 'notification_cta',
  // alerts
}
